import { useState, useEffect } from 'react'
import {Form, Table, Modal, Button, Toast, ToastContainer, Row, Col, InputGroup, ModalBody} from "react-bootstrap"
import { read, utils, writeFile } from 'xlsx';
import { useForm } from "react-hook-form"
import * as FileSaver from 'file-saver';
import axios from 'axios'

export default function Contactos() {
    
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/Files'
    const [contacts, setContacts] = useState([])
    const [rows , setRows] = useState([])
    const [viewUp, setUp] = useState(false)
    const [viewAdd, setAdd] = useState(false)
    const [load, setLoad] = useState(false)
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
      } = useForm({ mode: "onBlur" });
      
    const showUp = () => {setUp(true)}
    const hideUp = () => {setUp(false)}

    const showAdd = () => {setAdd(true)}
    const hideAdd = () => {setAdd(false)}

      const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const row = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    row.forEach(r => {                        
                    })
                    setRows(row)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    useEffect(()=>{
        console.log("get Contacts") 
    },[toast])

    const LoadData = (data,e) => {
        //subir a archivo.
        setLoad(true)        
        Toaster("success", "hit the road Jack")            
        setLoad(false)
        hideUp()        
    }

    const SaveContact = (data,e) => {
        console.log("guardar contacto en base de datos")
        hideAdd()
    }

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}
    return (<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={viewUp} onHide={hideUp}>
        <Modal.Header closeButton>
            <Modal.Title>Importar Contactos</Modal.Title>
        </Modal.Header>
            <Form onSubmit={handleSubmit(LoadData)}>
                <Modal.Body>
                    <Form.Label>Cargue el archivo xlsx con la data pertinente. </Form.Label>
                    <Form.Control type="file" required onChange={handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" disabled = {load} className="App-button">Importar</Button>
                    <Button className="App-button-alt" onClick={hideUp}>Cerrar</Button>
                </Modal.Footer>
            </Form>
    </Modal>
    <Modal show={viewAdd} onHide={hideAdd}>
        <Modal.Header closeButton>
            <Modal.Title>Nuevo Contacto</Modal.Title>
        </Modal.Header>
            <Form onSubmit={handleSubmit(SaveContact)}>
                <Modal.Body>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control className='mb-2' required  {...register("nombre")}/>
                    <Form.Label>Email</Form.Label>
                    <Form.Control className='mb-2' required  {...register("email")}/>
                    <Form.Label>Telefono</Form.Label>
                    <Form.Control className='mb-2' {...register("telefono")}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" disabled = {load} className="App-button">Agregar</Button>
                    <Button className="App-button-alt" onClick={hideAdd}>Cerrar</Button>
                </Modal.Footer>
            </Form>
    </Modal>
    <div className="content-container">        
        <div className="content-body-alt">
            <Button className="m-3 App-button" onClick={showAdd} > Nuevo Contacto </Button>            
            <Button className="m-3 App-button" onClick={showUp} > Subir Contactos </Button>            
            <Button className="m-3 App-button" /*FileSaver.saveAs(Image_URL+"/PreciosTemplate.xlsx")*/ > Formato </Button>            
                <Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>    
                            <th className="table-th">Nombre</th>
                            <th className="table-th">Email</th>
                            <th className="table-th">Numero </th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {contacts.map(el => {
                        return <tr>
                            <td className="table-td">{el.producto}</td>
                            <td className="table-td">{el.categoria}</td>
                            <td className="table-td">{el.marca}</td>                            
                        </tr>
                        })}
                    </tbody>
            </Table>
        </div>
    </div>
    </>)
}