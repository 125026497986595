import { useState, useEffect, useContext } from "react";
import {Form, Table, Modal, Button, Toast, ToastContainer, Row, Col, InputGroup, ModalBody} from "react-bootstrap"
import { useForm } from "react-hook-form"
import { read, utils, writeFile } from 'xlsx';
import axios from "axios";
import * as FileSaver from 'file-saver';
import ClientContext from "../../../Context/ClientContext";
import { useNavigate } from "react-router-dom";

export default function ListaPrecios() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/Files'
    const {logData} = useContext(ClientContext)
    const newLocation = useNavigate()

    const [encuestas, setEncuestas] = useState([]) //lista encuestas
    const [secciones, setSeccions] = useState([]) //secciones
    const [enc, setEnc] = useState({})
    const [sS, setSS] = useState() //seccion a cambiar.

    const [rows , setRows] = useState([])
    const [precios, setPrecios] = useState([])
    const [viewEliminar, setVE] = useState(false)
    const [viewUp, setUp] = useState(false)
    const [load, setLoad] = useState(false)
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
        return headers;
        }

    useEffect(()=>{
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then(
            function(response) {
                setEncuestas(response.data)
            })
        axios.get(API_URL+'/precios', {params: {client_id:logData.id}}).then(res => {
            setRows(res.data)
        })
    },[toast])

    const handleChange = e => {
        e.preventDefault();
        axios.get(API_URL + '/seccion', { params: {encuesta_id:e.target.value} }).then(
            function(response) {
                setEnc(encuestas.find(en => en.id === Number(e.target.value)))
                setSeccions(response.data)
            })
    }
    const handleSectionChange = e => {
        e.preventDefault();
        let temp = secciones.find((seccion) => seccion.id == e.target.value)
        axios.get(API_URL+'/precios', {params: {client_id:logData.id}}).then(res => {
            let dat = res.data.filter(d => d.encuesta_id === enc.id)
            setPrecios(dat)
            setSS(temp);
        })
    }

    const showUp = () => {setUp(true)}
    const hideUp = () => {setUp(false)}

    const showEliminar = () => {setVE(true)}
    const hideEliminar = () => {setVE(false)}

    const LoadData = (data,e) => {
        //subir a archivo.
        setLoad(true)
        axios.post(API_URL+'/precios', rows).then(res => { 
            Toaster("success", res.data.message)
            setPrecios(rows)
            setLoad(false)
            hideUp()
        })
    }

    const LimpiarTablas = () => {
        setLoad(true)
        let headers = setHeaders()
        axios.delete(API_URL+`/precios/${enc.id}`, {headers}).then(res => {
            Toaster("success", "Tabla limpia")
            setPrecios([])
            setLoad(false)
            hideEliminar()
        })
    }

    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const row = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    row.forEach(r => {
                        r.encuesta_id = enc.id
                        r.client_id = logData.id
                        r.seccion_id = sS.id
                    })
                    setRows(row)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    return (<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={viewUp} onHide={hideUp}>
        <Modal.Header closeButton>
            <Modal.Title>Importar tabla de precios</Modal.Title>
        </Modal.Header>
            <Form onSubmit={handleSubmit(LoadData)}>
                <Modal.Body>
                    <Form.Label>Cargue el archivo xlsx con la data pertinente. </Form.Label>
                    <Form.Control type="file" required onChange={handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="App-button-alt" onClick={hideUp}>Cerrar</Button>
                    <Button type="submit" disabled = {load} className="App-button">Importar</Button>
                </Modal.Footer>
            </Form>
    </Modal>
    <Modal show={viewEliminar} onHide={hideEliminar}>
    <Modal.Header closeButton>
          <Modal.Title>Limpiar Tabla</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(LimpiarTablas)}>
            <Modal.Body>
                <h5>Esta seguro ?</h5>
                <h6>esta acción no puede deshacerse</h6>
            </Modal.Body>
            <Modal.Footer>
                <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                <Button type="submit" disabled = {load} className="App-button">Eliminar</Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <div className="content-container">
        <Form>
            <Row className="mb-2">
                <Form.Group as={Col}>
                    <Form.Select onChange={handleChange}>
                        <option>Seleccionar encuesta...</option>
                        {encuestas.map((encuesta) => <option value={encuesta.id}>{encuesta.nombre_encuesta}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                    <Button className="App-button" onClick={()=>newLocation("/app/administracion/encuestas")}>Nueva Encuesta</Button>
                </Form.Group>
            </Row>
            {secciones.length > 0 ? 
            <Row className="m-1 mb-2 mt-0">
                <Form.Group as={Col}>
                    <Form.Select onChange={handleSectionChange}>
                        <option>Seleccionar sección</option>
                        {secciones.map((seccion) => <option value={seccion.id}>{seccion.nombre_seccion}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                    <Button className="App-button" onClick={()=>newLocation("/app/administracion/secciones")}>Nueva seccion</Button>
                </Form.Group>
            </Row>
            : null}
        </Form>
        {sS ? 
            <div className="content-body-alt">
                <Button className="m-3 App-button" onClick={showUp}> Cargar Precios </Button>            
                <Button className="m-3 App-button" onClick={() => FileSaver.saveAs(Image_URL+"/PreciosTemplate.xlsx")}> Formato </Button>
                <Button className="m-3 App-button" onClick={showEliminar}> Limpiar precios </Button>
                {enc && enc.tipo_encuesta === "precios" ?<Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>    
                            <th className="table-th">Producto</th>
                            <th className="table-th">Categoria</th>
                            <th className="table-th">Marca</th>
                            <th className="table-th">Precio Base</th>
                        </tr>
                    </thead>
                    <tbody>
                        {precios.map(el => {
                        return <tr>
                            <td className="table-td">{el.producto}</td>
                            <td className="table-td">{el.categoria}</td>
                            <td className="table-td">{el.marca}</td>
                            <td className="table-td">{el.precio_base}</td>
                        </tr>
                        })}
                    </tbody>
                </Table>:
                <p>La encuesta Seleccionada no forma parte del servicio de Precios.</p>}
        </div>:null}
    </div>
    </>)
}