import { Button, Table, Modal, Form, Row, Col, Toast, ToastContainer, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState, useContext, useEffect } from "react";
import axios from "axios";
import "./Periodos.css";
import { BarLoader } from "react-spinners";
import {BiSearch, BiEditAlt, BiTrash} from 'react-icons/bi'
import ClientContext from "../../../Context/ClientContext";
import UserContext from '../../../Context/UserContext';
import { useNavigate } from "react-router-dom";

export default function Periodos() {
    const [loadBuffer, setBuffer] = useState(false)
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const newLocation = useNavigate()
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [periodos, setPeriodos] = useState([]);
    const [show, setShow] = useState(false);
    const [showEdit, setEditShow] = useState(false);
    const [viewElim, setElim] = useState(false);
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isDirty, isValid },
    } = useForm({ mode: "onChange" });
    
    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    const handleClose = () => {setShow(false);reset();}
    const handleShow = () => {setShow(true)}
    const hideEliminar = () => {setElim(false); reset()}
    const showEliminar = (period) => {setElim(true); reset({id:period.id})}
    const handleEditClose = () => {setEditShow(false);reset();}
    const handleEditShow = (period) => {
        reset({
            id: period.id,
            nombre_periodo: period.nombre_periodo,
            nombre_corto: period.nombre_corto,
            periodo_inicio:period.periodo_inicio,
            periodo_fin:period.periodo_fin,
            validez: period.validez,
            estado: period.estado,
        })
        setEditShow(true)
    }
   
    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
        return headers;
        }

    const CallModal = (datax, e) => {
        setBuffer(true)
        e.preventDefault();
        axios.post(API_URL + '/periodo', datax).then(
            function (response) {
                Toaster("success", response.data.message)
                setBuffer(false)
                handleClose()
            }
        )
    }
    const EditModal = (datax, e) => {
        e.preventDefault();
        setBuffer(true)
        let headers = setHeaders();
        axios.post(API_URL + '/periodo/update', datax, {headers}).then(
            function (response) {
                Toaster("success", response.data.message)
                setBuffer(false)
                handleEditClose()
            }
        )
    }
    const Remove = () => {
        setBuffer(true)
        let pat = getValues()
        let headers = setHeaders()
        axios.delete(API_URL + `/periodo/${pat.id}`, {headers}).then(res => {
            Toaster("success", res.data.message)
            hideEliminar()
            setBuffer(false)
        })
    }
    useEffect(() => {
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(
            function(response) {
                response.data.sort(function(a,b){
                    return new Date(a.periodo_inicio) - new Date(b.periodo_inicio); //ordenar por fecha.
                  });
                setPeriodos(response.data)
            })
        },[toast])

    return(<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={viewElim} onHide={hideEliminar}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Periodo</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <h4>Esta seguro ?</h4>
                <h5>esta acción no puede deshacerse</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                <Button type="submit" className="App-button" disabled={loadBuffer} onClick={() => Remove()} >Eliminar</Button>
            </Modal.Footer>
    </Modal>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Periodo</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(CallModal)}>
            <Form.Control type="hidden" value={logData.id} {...register("client_id")}/>
            <Form.Control type="hidden" value={userData.subscription} {...register("subscription")} />
            <Modal.Body>
                <Row>
                <Form.Group as={Col} className="mb-2">
                    <Form.Label>Nombre del Periodo</Form.Label>
                    <Form.Control size="sm" placeholder="Nombre" {...register("nombre_periodo", {required:true})}/>
                     
                </Form.Group>
                <Form.Group as={Col} >
                    <Form.Label>Nombre corto</Form.Label>
                    <Form.Control size="sm" placeholder="Nmbr" {...register("nombre_corto", {required:true})} />
                     
                </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Fecha de inicio</Form.Label>
                        <Form.Control size="sm" type="date" {...register("periodo_inicio", {required:true})} />
                        {errors.periodo_fin && (<p>{errors.periodo_fin.message}</p>)}
                    </Form.Group>
                <Form.Group as={Col} className="mb-2">
                        <Form.Label>Fecha de término</Form.Label>
                        <Form.Control size="sm" type="date" {...register("periodo_fin",{
                            required: "La fecha final es obligatoria.",
                            validate: {
                            matchesPreviousPassword: (value) => {
                                const fin = new Date(value).getTime()
                                const inicio = new Date (getValues("periodo_inicio")).getTime();        
                                return fin-inicio > 0 || "No puede seleccionar una fecha anterior.";
                            }}
                        })} />
                </Form.Group> 
                </Row>
                <Row>
                <Form.Group as={Col} className="mb-2">
                        <Form.Label>Validez de período</Form.Label>
                        <Form.Select {...register("validez")}>
                            <option value="cerrado">Rango Cerrado</option>
                            <option value="flexible">Rango Flexible</option>
                        </Form.Select>
                    </Form.Group>   
                <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handleClose}>
                Cerrar
            </Button>
            {!loadBuffer ? <Button type="submit" className="App-button" disabled={!isDirty || !isValid} >
                Crear
            </Button> : <BarLoader className="m-2" color="#4538D1" width={50} />}
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={showEdit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Periodo</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(EditModal)}>
            <Form.Control type="hidden" value={logData.id} {...register("client_id")}/>
            <Modal.Body>
                <Row>
                <Form.Group as={Col} className="mb-2">
                    <Form.Label>Nombre del Periodo</Form.Label>
                    <Form.Control size="sm" placeholder="Nombre" {...register("nombre_periodo", {required:true})}/>
                </Form.Group>
                <Form.Group as={Col} >
                    <Form.Label>Nombre corto</Form.Label>
                    <Form.Control size="sm" placeholder="Nmbr" {...register("nombre_corto", {required:true})} />
                </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Fecha de inicio</Form.Label>
                        <Form.Control size="sm" type="date" {...register("periodo_inicio", {
                            required: "La fecha de inicio es obligatoria.",
                            validate: {
                            matchesPreviousPassword: (value) => {
                                const fin = new Date(getValues("periodo_fin")).getTime()
                                const inicio = new Date(value).getTime();
                                return fin-inicio > 0 || "No puede seleccionar una fecha anterior.";
                            }}})} />
                            {errors.periodo_fin && (<p>{errors.periodo_fin.message}</p>)}                        
                    </Form.Group>
                <Form.Group as={Col} className="mb-2">
                        <Form.Label>Fecha de término</Form.Label>
                        <Form.Control size="sm" type="date" {...register("periodo_fin",{
                            required: "La fecha final es obligatoria.",
                            validate: {
                                matchesPreviousPassword: (value) => {
                                    const fin = new Date(value).getTime()
                                    const inicio = new Date (getValues("periodo_inicio")).getTime();
                                    return fin-inicio > 0 || "No puede seleccionar una fecha anterior.";
                                }}
                            })} />
                    </Form.Group> 
                </Row>
                <Row>                    
                <Form.Group as={Col} className="mb-2">
                        <Form.Label>Validez de período</Form.Label>
                        <Form.Select {...register("validez")}>
                            <option value="cerrado">Rango Cerrado</option>
                            <option value="flexible">Rango Flexible</option>
                        </Form.Select>
                    </Form.Group>                
                <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                    </Form.Group>
                </Row>                    
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handleEditClose}>
                Cerrar
            </Button>
            {!loadBuffer ? <Button type="submit" className="App-button" disabled={!isDirty || !isValid} >
                Actualizar
            </Button> : <BarLoader className="m-2" color="#4538D1" width={50} />}
            </Modal.Footer>
        </Form>
      </Modal>
        <div className="content-container">
            <div className="content-header"><h4>Periodos</h4></div>
            <div><Row className="m-3 ">
                <Col><Button onClick={handleShow} className="App-button"> Nuevo Periodo </Button></Col>
                <Col><InputGroup>
                    <Form.Control placeholder="Buscar Periodo..." />
                        <Button onClick={() => {/*filter by field*/}} className="App-button"><BiSearch /></Button>
                    </InputGroup></Col>
                </Row>
                <Row><h6>Lista de periodos</h6></Row>
                <Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>                        
                            <th className="table-th">Periodos</th>
                            <th className="table-th">Nombre corto</th>
                            <th className="table-th">Fecha de inicio</th>
                            <th className="table-th">Fecha de termino</th>
                            <th className="table-th">Estado</th>
                            <th className="table-th">Validez</th>
                            <th></th>                        
                        </tr>
                    </thead>
                    <tbody>
                            {periodos.map((period) => 
                               <tr>                            
                                    <td>{period.nombre_periodo}</td>
                                    <td>{period.nombre_corto}</td>
                                    <td>{period.periodo_inicio}</td>
                                    <td>{period.periodo_fin}</td>
                                    <td>{period.validez}</td>
                                    <td>{period.estado}</td>
                                    <td className="clickable-end">
                                        <td className="clickable"><BiEditAlt onClick={() => handleEditShow(period)}/></td>
                                        <td className="clickable"><BiTrash onClick={() => showEliminar(period)} /></td>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </Table>
            </div>
            <div className="flex-end">
                <Button className="App-button" onClick={() => {newLocation("../encuestas")}}>Siguiente</Button>
            </div>
        </div>
    </>)
}