import { Form, Button, Modal, Row, Col, Table, Toast, ToastContainer } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useState, useContext, useEffect } from "react"
import {BiSearch, BiEditAlt, BiTrash} from 'react-icons/bi'
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Zonas() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const newLocation = useNavigate()
    const { logData } = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [paises, setPaises] = useState([])
    const [selPais, setSelPais] = useState({});
    const [zonas, setZonas] = useState([])
    const [show, setShow] = useState(false);
    const [showPais, setPaisShow] = useState(false);
    const [showEdit, setEditShow] = useState(false);
    const [viewEliminar, setViewEliminar] = useState(false)

    const [load, setLoad] = useState(false)
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    const handleClose = () =>{setShow(false);updateList();}
    const handleShow = () => {
        reset({
            id: null,
            nombre_zona: null,
            estado: "activo", 
        })
        setShow(true);}
    
    const handleEditClose = () => {setEditShow(false);updateList();}
    const handleEditShow = (zona) => {
        reset({
           id: zona.id,
           pais_id: zona.pais_id,
           nombre_zona: zona.nombre_zona,
           estado: zona.estado,
        })
        setEditShow(true);}

    const hideEliminar = () => {setViewEliminar(false);updateList();}
    const showEliminar = (zona) => {
        reset({
            id: zona.id,
            pais_id: zona.pais_id,
            nombre_zona: zona.nombre_zona,
            estado: zona.estado,
        })
        setViewEliminar(true)
    }

    const handlePaisClose = () => setPaisShow(false);
    const handlePaisShow = () =>{
        reset(null)
        setPaisShow(true);}
    
    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
            };
        return headers;}

    const CallModal = (datax, e) => {
        setLoad(true)
        e.preventDefault();
        axios.post(API_URL + '/zona', datax).then(
            function (response) {
                Toaster("success", response.data.message)
                handleClose()
                setLoad(false)
            })
    }

    const EditZona = (datax, e) => {
        setLoad(true)
        e.preventDefault();
        let headers = setHeaders()
        axios.post(API_URL + '/zona/update', datax, {headers}).then(
            function (response) {
                Toaster("success", response.data.message)
                handleEditClose()
                setLoad(false)
            })
    }
    const RemoveZona = (data,e) => {
        e.preventDefault()
        setLoad(true)
        let headers = setHeaders()
        axios.delete(API_URL + `/zona/${data.id}`, {headers}).then(res =>{
            Toaster("success", res.data.message)
            hideEliminar()
            setLoad(false)
        })
    }

    const PaisModal = (data,e) => {
        e.preventDefault()
        axios.post(API_URL + '/pais', data).then((res) => {
            Toaster("success", res.data.message)
            handlePaisClose();
        })
    }

    function updateList() {
        axios.get(API_URL + '/zona',{ params:{pais_id:selPais.id, client_id: logData.id}}).then(
            (response) => {setZonas(response.data)}  
           )
    }
    const handleChange = e =>{
        e.preventDefault();
        let temp = paises.find((pais) => pais.id == e.target.value)
        axios.get(API_URL + '/zona',{ params:{pais_id:e.target.value, client_id: logData.id}}).then(
         (response) => {setZonas(response.data)}  
        )
        setSelPais(temp)
    }

function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

useEffect(() => {
    axios.get(API_URL + '/pais').then((response) =>
    {setPaises(response.data)})
},[toast])

 return (<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={showPais} onHide={handlePaisClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Pais</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(PaisModal)}>
           <Modal.Body>
                <Form.Label>Nombre del Pais</Form.Label>
                <Form.Control {...register("nombre_pais")} />
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handlePaisClose}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button">
                Crear
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nueva zona</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(CallModal)}>
        <Form.Control type="hidden" value={logData.id} {...register("client_id")} />
            <Modal.Body>
                <Form.Group className="mb-2">
                    <Form.Label>Pais</Form.Label>
                    {selPais && selPais.id ? <Form.Control type="hidden" defaultValue={selPais.id} {...register("pais_id")}/> : null}
                    {selPais && selPais.id ? <Form.Control readOnly defaultValue={selPais.nombre_pais}/> : null}
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Nombre de la zona</Form.Label>
                    <Form.Control size="sm" placeholder="Nombre" {...register("nombre_zona")}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Estado</Form.Label>                
                    <Form.Select {...register("estado")}>
                        <option value="activo">Activo</option>
                        <option value="inactivo">Inactivo</option>
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handleClose}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={load} onClick={handleClose}>
                Crear
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showEdit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar zona</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(EditZona)}>
            <Modal.Body>
                <Form.Group className="mb-2">
                    <Form.Label>Pais</Form.Label>
                        <Form.Text className="m-2">
                        {selPais ? selPais.nombre_pais: null}
                        </Form.Text>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Nombre de la zona</Form.Label>
                    <Form.Control size="sm" placeholder="Nombre" {...register("nombre_zona")}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Estado</Form.Label>                
                    <Form.Select {...register("estado")}>
                        <option value="activo">Activo</option>
                        <option value="inactivo">Inactivo</option>
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handleEditClose}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={load}>
                Actualizar
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={viewEliminar} onHide={hideEliminar}>
    <Modal.Header closeButton>
          <Modal.Title>Eliminar Zona</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(RemoveZona)}>
            <Modal.Body>
                <h4>Esta seguro ?</h4>
                <h5>esta acción no puede deshacerse</h5>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                <Button type="submit" className="App-button" disabled={load}>Eliminar</Button>
            </Modal.Footer>
        </Form>
    </Modal>

    <div className="content-container">
    <div className="content-header"><h4>País</h4></div>
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Select className="mb-2" onChange={handleChange}>
                            <option>Seleccionar Pais...</option>
                            {paises.map((pais) => <option value={pais.id}>{pais.nombre_pais}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                    {userData && userData.id === 1 ? <Button className="App-button" onClick={handlePaisShow}>nuevo Pais</Button> : null}
                    </Form.Group>
                </Row>
            </Form>
        {selPais && selPais.id ? 
        <div className="content-body-alt">
        <div className="content-header"><h4>Zonas</h4></div>
        <Button className="m-3 App-button" onClick={handleShow}> Nueva zona </Button>
        <Table className="App-Table" striped responsive="md" hover size="sm">
            <thead>
                <tr>                    
                    <th className="table-th">Nombre</th>
                    <th className="table-th">Estado</th>
                    <th></th>                        
                </tr>
            </thead>
            <tbody>
                    {zonas.map((zona) => 
                       <tr>                            
                            <td>{zona.nombre_zona}</td>
                            <td>{zona.estado}</td>
                            <td className="clickable-end">
                                <td className="clickable"><BiEditAlt onClick={() =>handleEditShow(zona)}/></td>
                                <td className="clickable"><BiTrash onClick={() => showEliminar(zona)}></BiTrash></td>
                            </td>
                        </tr>
                    )}
            </tbody>
        </Table>
</div>:null}
<div className="flex-between">
    <Button className="App-button" onClick={() => {newLocation("../preguntas")}}>Atrás</Button>
    <Button className="App-button" onClick={() => {newLocation("../ciudades")}}>Siguiente</Button>
</div>
    </div>
    </>)
}