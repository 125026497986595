import { useState } from "react";
import { Button, Form , Row, Col, InputGroup, Modal, Toast, ToastContainer} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners"
import axios from "axios";
import loginBanner from '../../../Images/LoginBanner.png'
import logo from '../../../Images/logo2.png'
import "./Login.css";

export default function LoginScreen() {
  const API_URL = 'https://api.medicionanalytic.com/api'
  /* const API_URL = 'http://127.0.0.1:8000/api' */
  const newLocation = useNavigate();
  const [loading, setLoading] = useState(false)
  const [registerView, setRegister] = useState(false)
  const [olvidoView, setOlvido] = useState(false)
  const [Valid, setValid] = useState(false)
  const { 
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur" });
  const [toast, setToast] = useState({ show: false, variant: "", message: "" });

  const onSubmit = () => {
    let datax = getValues()    
    setLoading(true)
    axios.post(API_URL + "/login", datax).then(res => {
      //window.sessionStorage.setItem("sessionKey", res.data.access_token)
      window.localStorage.setItem("sessionKey", res.data.access_token)
      setLoading(false)
      newLocation("/app/inicio");
    }).catch(err => {
      Toaster("danger", "Usuario o contraseña errados")
      setLoading(false)})
  };
 
  const RegisterNew = (data,e) => {
    setLoading(true)
    if(data.file) { data.logo = data.file[0]}
    e.preventDefault()
    let formdata = new FormData();    
    if(data.file) { formdata.append("logo",data.logo)};
    formdata.append("nombre_cliente", data.nombre_cliente);
    formdata.append("usuario", data.user)
    formdata.append("password", data.pass)
    formdata.append("telefono",data.phone)
    formdata.append("email", data.email)
    formdata.append("subscription", data.subscription)        
          axios.post(API_URL + '/usuario/trial', formdata).then((res) => {         
            Toaster("success", res.data.message)
            setRegister(false)
            reset()
            reset({usuario:null, password:null})
            setLoading(false)
        }).catch(err => {          
          if (err.response.data.message.errorInfo[1] === 1062) {
            Toaster("warning", "El usuario o correo ya existe en nuestro sistema")
          }
          else {
            Toaster("danger", "Algo Salio Mal")
          }
          setLoading(false)
        })
  }

const CheckOlvido = (data,e) => {
  e.preventDefault()
  let formdata = new FormData();
  formdata.append("email", data.email)
  //axios.post(API_URL+"/usuario/test", formdata).then(res => {
  axios.post(API_URL+"/usuario/recover", formdata).then(res => {
    Toaster("success", res.data.message)
    setOlvido(false)
  }).catch(err => {  Toaster("danger", "Algo Salio Mal") })
//  Toaster("danger", "Estamos trabajando en esta función")
}

function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

  return (<>
  <ToastContainer style={{ position: "fixed", top: "80vh", right: "0vw" }} className="p-3">
    <Toast bg={toast.variant} onClose={() => setToast({ show: false, variant: "", message: "" })}
                show={toast.show} delay={3000} autohide>
      <Toast.Header><strong className="me-auto">Medicion Analytic</strong><small>just now</small></Toast.Header>
      <Toast.Body className="text-white">{toast.message}</Toast.Body>
    </Toast>
  </ToastContainer>  
    <Modal show={olvidoView} >
        <Modal.Header closeButton>
            <Modal.Title>Reestablecer contraseña</Modal.Title>
        </Modal.Header>
            <Form  onSubmit={handleSubmit(CheckOlvido)}>                
                <Modal.Body>                                    
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Email</Form.Label>
                            <Form.Control size="sm" {...register("email", {required:true})} />                    
                        </Form.Group>                                             
                    </Row>                                        
                </Modal.Body>
                <Modal.Footer>
                    <Button className="App-button-alt" onClick={() => {setOlvido(false);reset({email:""})}}>Cerrar</Button>
                    <Button type="submit" disabled = {!loading && isValid ? false:true} onClick={() => setOlvido(false)} className="App-button">Enviar</Button>
                </Modal.Footer>
            </Form>
    </Modal>
    <div className="Main">    
    <div className="container">
      <img src={loginBanner} className="login-banner" alt="" />
    </div>
    {registerView ? 
    <div className="container login-box">
      <Form className="form-login" onSubmit={handleSubmit(RegisterNew)}>
        <Form.Control type="hidden" value={1} {...register("admin_id")} />
        <Form.Control type="hidden" value={"activo"} {...register("servicio_encuesta")} />
        <Form.Control type="hidden" value={"inactivo"} {...register("servicio_audit")} />
        <Form.Control type="hidden" value={"activo"} {...register("servicio_mystery")} />
        <Form.Control type="hidden" value={"inactivo"} {...register("servicio_shop")}/>
        <Form.Control type="hidden" value={"trial"} {...register("subscription")}/>
        <Row className="button-holder m-2">
          <img src={logo} className="logo" alt="" />
        </Row>
        <Row className="mb-3">
          <Col className="button-holder">
            <h4 className="Title">Registro</h4>
          </Col>
          <p className="option">Inicie con 10 encuestas GRATIS</p>
          <span className="red">*Recuerda tu nombre de usuario y contraseña para acceder</span>
        </Row>                
            <Form.Control size="sm" placeholder="Nombre de usuario" className="form-field" {...register("user")} />                                                                                                
          <Form.Control className="form-field mb-2" size="sm" type="password" placeholder="Contraseña" {...register("pass", {
                           onChange: (e) => {                            
                            if(!e.target.value.match(/[^a-zA-Z\d]/) || (!e.target.value.match(/[a-z]/) && !e.target.value.match(/[A-Z]/)) || !e.target.value.match(/\d/) || !e.target.value.length > 8) {setValid(false); return}                                                        
                            else {setValid(true)}
                          },
                           onBlur: (e) => {
                            if(!e.target.value.match(/[a-z]/) && !e.target.value.match(/[A-Z]/)) { Toaster("danger", "Para una contraseña segura, incluye una combinación de letras mayusculas y minusculas.");return}
                            if(!e.target.value.match(/\d/)) { Toaster("danger", "Para una contraseña segura incluye por lo menos un numero.");return}
                            if(!e.target.value.length > 8) {Toaster("danger", "Las contraseñas seguras tienen un minimo de 8 caracteres.");return}
                            if(!e.target.value.match(/[^a-zA-Z\d]/)) {Toaster("danger", "Para una contraseña segura incluye un simbolo.");return}
                           }
                          })} />                    
          <Form.Control className="form-field mb-2" size="sm" type="password" placeholder="Confirmar Contraseña" {...register("confirmPass", {required:true, validate: (val) => {if (watch('pass') != val) {Toaster("danger", "ambas contraseñas deben ser iguales");}} })}/>          
            <Form.Control size="sm" placeholder="Email" className="form-field" {...register("email", {required:true})} />                                                                  
            <Form.Control size="sm" placeholder="Telefono" type="number" className="form-field" {...register("phone", {required:true})} />                                                                  
            <Form.Control size="sm" placeholder="Nombre de empresa" className="form-field" {...register("nombre_cliente")} />              
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 mt-0 m-2">logo de empresa</Form.Label>
              <Form.Control type="file" size="sm" accept="image/*" {...register("file")}/>
              <Form.Text className="text-muted">preferiblemente utilizar fotos png de ratio uniforme</Form.Text>
            </Form.Group>                                                                  
        <Row className="button-holder mt-3">
          {loading ? <BarLoader className="m-2" color="#4538D1" width={200} />          
          :<Button type="submit" size="sm" disabled = {!loading && isValid ? false:true} className="Button">Registrarse</Button>}
        </Row>
        <Row className="button-holder mt-2">
          <Button size="sm" onClick={() => {setRegister(false);reset({usuario:null, password:null})}} className="Button-alt">Volver</Button>
        </Row>
      </Form>
    <Row className="m-4">
      <p className="footer"><span style={{fontWeight:"bold"}}>Medición Analytic Platform </span> by Pagovision Inc. 2024</p>
    </Row>
    </div>:<div className="container login-box">
      <Form className="form-login">
        <Row className="button-holder m-2">
          <img src={logo} className="logo" alt="" />
        </Row>
        <Row className="mb-3">
          <Col className="button-holder">
            <h4 className="Title">Login</h4>
          </Col>
          <p className="option">Inicie sesión ingresando sus datos</p>
          {
            //<Button onClick={() => {axios.get(API_URL+'/test').then(res => {console.log(res.data.filter(r => {return r.sucursal === 38}))})}}> Execute </Button>
          }          
        </Row>        
        <Form.Control  className="form-field mb-2" type="text" name="user" placeholder="Usuario" {...register("usuario")} />        
        <Form.Control  className="form-field mb-2" type="password" name="password" placeholder="Contraseña" {...register("password")} />
          <Row className="mt-3 option">
            <p className="option-alt" onClick={() => setOlvido(true)}>¿Olvidaste tu contraseña?</p>
          </Row>
        <Row className="button-holder mt-3">
          {loading ? <BarLoader className="m-2" color="#4538D1" width={200} />          
          :<Button className="Button" type="submit" onClick={() => {onSubmit()}} size="sm">Ingresar</Button>}
        </Row>
        <Row className="button-holder mt-2">
          <Button size="sm" onClick={() => {setRegister(true);reset()}} className="Button-alt">Registrarse</Button>
        </Row>
      </Form>
    <Row className="m-4">
      <p className="footer"><span style={{fontWeight:"bold"}}>Medición Analytic Platform </span> by Pagovision Inc. 2024</p>
    </Row>
    </div>}
    </div>
  </>)
}