
import ClientContext from "../../../Context/ClientContext";
import axios from "axios";
import {useState,useContext,useEffect} from "react"
import { Form, Button, Table, Toast, ToastContainer,} from "react-bootstrap";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'
import { useForm } from "react-hook-form";

export default function ReportePA() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    //const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/app/public'    
    const {logData} = useContext(ClientContext)
    const re = /(?:\.([^.]+))?$/; //get extension
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const [puntos, setPuntos] = useState([])
    const [planes, setPA] = useState([])
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    const consulta = (data,e) => {
        e.preventDefault()
    }
    
    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        let Document = planes //data de planes
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }

    useEffect(() => {
        axios.get(API_URL + '/plan', { params: { client_id:logData.id } }).then(
                function(response) {
                    response.data.sort(function(a,b){
                        return new Date(a.plan_inicio) - new Date(b.plan_inicio); //ordenar por fecha.
                      });
                    setPA(response.data)
                })
        axios.get(API_URL + '/sucursal', { params: {cliente_id:logData.id} }).then(
            (res) => {
                res.data.forEach(p => {
                    p.label = p.nombre_sucursal;
                    p.value = p.id;
                })
                setPuntos(res.data)})
            },[toast])

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    function getResponse(pa) {
        if(pa === 1) {return "si"}
        else if(pa === 0) {return "no"}
        else {return "no evaluada"}
    }

    return (<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <div className="content-container">
        <div className="content-body-alt mb-4">
            <h3 className="p-2">Reporte de Planes de accion</h3>
            <Form onSubmit={handleSubmit(consulta)}>
            <Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>
                            <th className="table-th">Plan de accion</th>
                            <th className="table-th">Sucursal</th>                        
                            <th className="table-th">Fecha inicio</th>                        
                            <th className="table-th">Fecha final</th>                                 
                            <th className="table-th">Cumple</th>
                        </tr>
                    </thead>
                    <tbody>
                            {planes.map((pa) => 
                               <tr>
                                    <td>{pa.nombre_plan}</td>   
                                    <td>{pa.punto_id && puntos.length > 0 ? puntos.find(p => {return p.id === pa.punto_id}).nombre_sucursal : pa.punto_id}</td>
                                    <td>{pa.plan_inicio}</td>
                                    <td>{pa.plan_fin}</td>
                                    <td style={{textAlign:"center"}}>
                                        {getResponse(pa.cumple)}
                                    </td>                        
                                </tr>
                            )}
                    </tbody>
                </Table>
                <Button type="submit" className="m-2 App-button" onClick={exportToExcel}>Descargar Excel</Button>
            </Form>
        </div>
    </div>
    </>)
}