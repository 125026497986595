import { useState, useContext, useEffect } from "react";
import { Form, Button, Table, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import {format } from 'date-fns';
import ClientContext from "../../../Context/ClientContext";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";
import UserContext from "../../../Context/UserContext";

export default function ReportePreguntasAB() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [consulta, setConsulta] = useState(false)
    
    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()

    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [sSecciones, setSS] = useState([])

    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])
    const [reportes, setRep] = useState([])
    
    const [preguntas, setPreg] = useState([])
    const [respuestas, setR] = useState([])
    const [dataFiltered, setDF] = useState([])
    
    const [SSec, setSSec] = useState([])
    const [FP, setFP] = useState([])
    const [PS, setPS] = useState([])
    const [Basencuestas, setBaseEncuestas] = useState([])
    const [joints, setJoints] = useState([])
    const [cities, setCities] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.

   const {
       register,
       handleSubmit,
       reset,
       formState: { errors },
     } = useForm({ mode: "onBlur" });

     const consultaData = (data,e) => {
       e.preventDefault()
   }

   useEffect(()=>{
    axios.get(API_URL + '/joint', { params: { client_id:Number(logData.id) } }).then(res => { setJoints(res.data) })                
    axios.get(API_URL+'/respuesta', {params: { client_id:logData.id}}).then(res=>{ // modificar segun nueva estructura.
        let temp = res.data             
        let temp2 = [] 
        axios.get(API_URL + '/video', { params: { client_id: logData.id} }).then((response) => {                 
            let videos = response.data.filter(v => {return v.estado !== "inactivo"})                                
            if(userData.permiso === "admin" || userData.permiso === "gerente") {temp2 = temp; }
            else { let tetemp = temp.filter(el=> { return userData.encuestas.split(",").some(s => {return Number(el.encuesta_id) === Number(s) }) })                   
            temp2 = tetemp.filter(p => { return videos.some(v => {return (p.video_id === v.id || p.video_id === null)})}); }
            setR(temp2)
        })
    })
    axios.get(API_URL + '/periodo', { params: {client_id:logData.id } }).then(
        (response) => {
            response.data.forEach(p => {
                p.label = p.nombre_periodo;
                p.value = p.id;
            })
            response.data.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
            setPeriodos(response.data)
        })
        axios.get(API_URL + '/ciudad',{ params:{ client_id:logData.id }}).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then((res) => {
                res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })                
                if(logData.id === 49) {setPuntos(res.data)}
                else {setSuc(res.data)} })
    axios.get(API_URL + '/seccion/report', { params: {client_id:logData.id} }).then(
        function (response) {
            response.data.forEach(p => {
                p.label = p.nombre_seccion;
                p.value = p.id;
            })
            setSecciones(response.data)})
    axios.get(API_URL + '/encuesta/all', { params: {client_id:logData.id} }).then((response) => {
        let n;
            let temp;
            if(typeof response.data === 'object') {temp = Object.keys(response.data).map((key) => response.data[key]);}
            else { temp = response.data }
            if(userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta");}
            else {let vat = temp.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta");}
            setBaseEncuestas(n)
    })
    axios.get(API_URL + '/pregunta/all', {params: {client_id: logData.id}}).then((res)=>{setPreg(res.data)})
},[])    
    const tooltip = (name) => {
      return <Tooltip id="tooltip">
      <strong>{name}</strong>
    </Tooltip>
    }
      //format for excel Download
   const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
   const fileExtension = '.xlsx';

   const exportToExcel = async () => {
       let Document = dataFiltered.sort((a,b) => a.Punto_id - b.Punto_id)
       Document.forEach((d) => {
           delete d.Punto_id;
           delete d.Periodo_id;
           delete d.Tipo_pregunta;
       })
       const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
       const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
       const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
       const data = new Blob([excelBuffer],{ type:fileType });
       FileSaver.saveAs(data, 'Excel Export' + fileExtension)
   }

   function showData(filtered) {    
    setDF(filtered)
    setConsulta(true)
    }
    const getDateshort = (report) => {
        if(reportes.find(r => r.id === report) !== undefined){                     
            const formattedDate = format(reportes.find(r => r.id === report).created_at, 'dd/MM/H:mm');            
            return formattedDate
        }
    }
function Tabulada(){
    let data; 
    let sum = []
    respuestas.filter(r => r.encuesta_id === enc.id)
    .forEach((r)=>{
        /*                
        p.tipo_pregunta === "selectm" 
        p.tipo_pregunta === "info"
        */
        if(r.tipo_pregunta === "texto" || r.tipo_pregunta === "comentario" || r.tipo_pregunta === "select") {
            data = {            
                Respuesta_id: r.id,
                Value :r.respuesta, 
                Reporte_id: r.reporte_id, 
                Nombre_sucursal: puntos.find(obj => {return obj.id === r.punto_id}).nombre_sucursal,
                Punto_id: r.punto_id,
                Periodo_id: r.periodo_id,
                Encuesta_id: r.encuesta_id, 
                Seccion_id: r.seccion_id,
                Pregunta: r.pregunta,
                Pregunta_id: r.pregunta_id,
                Tipo_pregunta: r.tipo_pregunta,
                Video_id : r.video_id
          }
            sum.push(data)
        }
    })    
    return sum;
  }

  const SetFilters = (data,e) => {
    let tab = Tabulada()    
    e.preventDefault();    
    let temp = []
    if(!enc) {setConsulta(false);return;}
    temp =  tab.filter(el => {
        return sPuntos.some(p => {
            return sSecciones.some(s => {
               return sPeriods.some(f => {
                return p.id === el.Punto_id && 
                s.id === el.Seccion_id && 
                f.id === el.Periodo_id && 
                el.Encuesta_id === enc.id
               })
            })
        })
    })

    setFP(sPuntos);
    setPS(sPeriods);
    setSSec(sSecciones);
    showData(temp)
}

const handleEncuesta = (e) => {
    e.preventDefault()
    setSS([])
    let sec = []
    if(userData.permiso === "admin")   
    { sec = secciones}
    else {
      sec = secciones.filter(el=>{ return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
    }
    let en = encuestas.find(el => {return el.id === Number(e.target.value)})
    let tem = sec.filter(s => {return s.encuesta_id === Number(e.target.value)})
    if(en) {
        axios.get(API_URL+'/reporte', {params:{encuesta:Number(e.target.value)}}).then(res =>{setRep(res.data)})
        setE(en)
        setS(tem)
    }
}
const handleCity = (e) => {  
    e.preventDefault()       
    setSPtos([])        
    let sucursales = puntos.filter(s => {return Number(s.ciudad_id) === Number(e.target.value)})        
    setSuc(sucursales)
}
const handlePerdiod = (e) => {    
    setEncuestas([])    
    if(sPeriods.length > 0) {        
    let temp = joints.filter(j => { return sPeriods.some(p => { return Number(p.id) === Number(j.periodo_id) })})
    let temp2 = Basencuestas.filter(e => { return temp.some(j => {return Number(j.encuesta_id) === Number(e.id) }) })      
    setEncuestas(temp2)
    }
  }  
return (<>
       <div className="content-container">
       <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">
                            <Col>
                                <MultiSelect
                                overrideStrings={{
                                    "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Periodo",
                                    "create": "Create",}
                                  }
                                 options={periodos}
                                 value={sPeriods}
                                 onChange={setSP}
                                 onMenuToggle={handlePerdiod}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <Form.Select onClick={handleEncuesta}>
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.length > 0 ?encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}):null}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                {logData.id === 49 ? 
                                    <Form.Select onChange={handleCity} >
                                        <option value={null}>Sin Ciudad Seleccionada</option>
                                        {cities.length > 0 ? cities.map(enc => {return <option value={enc.id}>{enc.nombre_ciudad}</option>}):null}
                                    </Form.Select>
                                    : <MultiSelect
                                    overrideStrings={{
                                    "allItemsAreSelected": "Todo Seleccionado.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",}
                                    }
                                    options={Secciones}
                                    value={sSecciones}
                                    onChange={setSS}
                                    labelledBy="Select"
                                />}
                            </Col>
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Puntos",
                                    "create": "Create",}
                                  }
                                  options={userData.permiso === "admin" ?
                                  puntos   :
                                  puntos.filter(el => {                                        
                                             return  userData.puntos.split(",").indexOf(el.id.toString()) > -1                                    
                                     })}
                                 value={sPuntos}
                                 onChange={setSPtos}
                                 labelledBy="Select"
                                />
                            </Col>
                        </Row>
                        {logData.id === 49 ? <Row className="mb-1">
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todo Seleccionado.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",}
                                  }
                                 options={Secciones}
                                 value={sSecciones}
                                 onChange={setSS}
                                 labelledBy="Select"
                                />
                            </Col>
                        </Row>: null}
                        <Row className="m-1 mt-3">
                            <Button type="submit" className="App-button m-2 mt-0">Aplicar filtros <BiSearchAlt/></Button>                          
                        </Row>
    </Form>
       {consulta ? 
       <div className="content-body-alt mb-4">
           <Row className="row-excel-button">
                           <h3 className="p-2" style={{width:'50vw'}}>Resultados de reportes por Preguntas</h3>
                           <Button size="sm" className="App-button" onClick={exportToExcel}>Descargar Excel</Button>    
                       </Row>
           <Table className="App-Table" striped responsive="md" hover size="sm">
                       <thead>
                       <tr>
                            <th className="table-th" rowSpan={3}>Seccion</th>
                            <th className="table-th extended" rowSpan={3}>Preguntas</th>      
                            {PS.filter(pnt => {if(dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) {return pnt}})
                            .map(period => {return <th className="table-th">{period.nombre_corto}</th>})}
                        </tr>
                        <tr>                   
                        {PS.filter(pnt => {if(dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) {return pnt}})
                        .map(per => {                            
                            let untos = []
                            let sucs = []                            
                            dataFiltered.forEach(p => { if(sucs.findIndex(rep => rep.Reporte_id === p.Reporte_id) === -1) { sucs.push(p) } })
                        return <th>{
                                dataFiltered.filter(res => res.Periodo_id === per.id)
                                .sort((a, b) => a.Punto_id > b.Punto_id && a.Reporte_id > b.Reporte_id ? 1 : -1)
                                .map(res => {                                                                        
                                    let reortes = []
                                    const inde = untos.findIndex(rep => rep.Punto_id === res.Punto_id)                                    
                                    if(inde === -1) { untos.push(res)                                                                                          
                                        return <th>
                                        <tr><th className="table-th" style={{minWidth: (sucs.filter(s => s.Punto_id === res.Punto_id && s.Periodo_id === res.Periodo_id).length)*6+'rem'}}>{res.Nombre_sucursal}</th></tr>
                                        <tr><th>{   
                                         dataFiltered.filter(ress => ress.Punto_id === res.Punto_id && ress.Periodo_id === res.Periodo_id)
                                        .sort((a, b) => a.Punto_id < b.Punto_id )
                                        .map( ress => {                                                                                        
                                        const indess = reortes.findIndex(rep => rep.Reporte_id === ress.Reporte_id)
                                        if(indess === -1) { reortes.push(ress)
                                                return <OverlayTrigger placement="bottom" overlay={tooltip(getDateshort(ress.Reporte_id))}>
                                                <th className="table-th">{getDateshort(ress.Reporte_id)}</th>
                                                </OverlayTrigger>
                                            }
                                        })
                                    }</th></tr>
                                        </th> 
                                    }                                    
                                })
                            }</th>                                   
                        })}
                        </tr>       
                       </thead>
                       <tbody>
                       {SSec.map(s => {
                    return preguntas.filter(p => p.seccion_id === s.id && (p.tipo_pregunta === "texto" || p.tipo_pregunta === "comentario" || p.tipo_pregunta === "info" || p.tipo_pregunta === "select") && p.tipo_pregunta !== "load")
                    .map((dt, index) => {
                        return <tr>
                            {index === 0 ? <td>{s.nombre_seccion}</td>: <td></td>}
                            <td className="expanded">{dt.titulo}</td>
                            {PS.filter(pnt => {if(dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) {return pnt}})
                            .map(per => { 
                                let reortes = []
                                dataFiltered.forEach(res => {
                                    let indep = reortes.findIndex(rep => rep.Reporte_id === res.Reporte_id)
                                    if(indep === -1) { reortes.push(res)}
                                })                                
                                 return <td>{                                
                                 reortes                                 
                                .sort((a, b) => a.Punto_id > b.Punto_id ? 1:-1)
                                .sort((a,b) => (a.Punto_id === b.Punto_id && a.Reporte_id < b.Reporte_id) ? -1 : 1)
                                .filter(rep => rep.Periodo_id === per.id)
                                .map(rep => {
                                    let item = dataFiltered.filter(data => data.Periodo_id === per.id && data.Reporte_id === rep.Reporte_id && data.Pregunta_id === dt.id)                                    
                                    return <td className="Fondo-Blank">{item.length > 0 ? item[0].Value : "NaN%"}</td>
                                })
                                }</td>})}
                            </tr>
                        })
                    })
                }
                       </tbody>
                   </Table>
           </div>: null}
       </div>
       </>)
}