import { Row, Col, Form, Modal, Table, Button, Toast, ToastContainer } from "react-bootstrap"
import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form"
import ClientContext from "../../../Context/ClientContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BiEditAlt } from "react-icons/bi";

export default function SegmentacionPuntos() {    
    const newLocation = useNavigate()
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const {logData} = useContext(ClientContext);
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });

    const [puntos, setPuntos] = useState([])
    const [FP, setFP] = useState([])
    const [segmentos, setSegmentos] = useState([])
    const [segView, setSegView] = useState(false)
    const [segEdit, setSegEdit] = useState(false)
    const [editView, setEV] = useState(false)

    const [loadBuffer, setBuffer] = useState(false)
    const showSegmento = () => setSegView(true)
    const hideSegmento = () => setSegView(false)
    const showES = (segmento) => {
        reset({
            id: segmento.id,
            aplica: segmento.aplica,
            aplica_calculo: segmento.aplica_calculo,
            client_id: segmento.client_id,
            descripcion: segmento.descripcion,
            estado: segmento.estado,
            nombre_segmento: segmento.nombre_segmento,
            peso: segmento.peso,
        })
        setSegEdit(true)}
    const hideES = () => setSegEdit(false)

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
        return headers;
        }

    const {
            register,
            handleSubmit,
            reset,
            formState: { errors },
            } = useForm({ mode: "onBlur" });

    const showEdit = (punto) => {
        reset({
            id: punto.id,
            nombre_sucursal : punto.nombre_sucursal,
            segmento: punto.segmento,
        });
        setEV(true)}
    const hideEdit = () => setEV(false)

    const handleChange = e => {
        e.preventDefault()
        let seg = segmentos.find(s => s.id === Number(e.target.value))
        console.log(seg)
        if(seg)
        {setFP(FP.filter(p => p.segmento === seg.nombre_segmento))}
        else { setFP(puntos)}
    }

    useEffect(() => {
        axios.get(API_URL+'/segmento', { params: { client_id:logData.id, tipo:"punto" } }).then((response) =>
        setSegmentos(response.data)
    )
        axios.get(API_URL + '/sucursal',{ params:{cliente_id:logData.id}}).then(
            (response) => {
                setPuntos(response.data)
                setFP(response.data)
            }  
           )
    },[toast])

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    const createSegmento = (data,e) => {
        e.preventDefault()
        setBuffer(true)
        axios.post(API_URL + '/segmento', data).then((response) => {
            reset();
            Toaster("success", response.data.message);
            setBuffer(false)
        })
    }

    const editSegmento = (data,e) => {
        e.preventDefault()
        setBuffer(true)
        let formData = new FormData();
        formData.append("_method", "PATCH");
        formData.append("client_id", data.client_id);
        formData.append("nombre_segmento", data.nombre_segmento);
        if(data.peso === null) {formData.append("peso", 0);}
        else {formData.append("peso", data.peso);}
        formData.append("estado", data.estado);
        if(data.descripcion === null) {
            formData.append("descripcion", " ");
        }
        else {formData.append("descripcion", data.descripcion);}
        if(data.aplica) {formData.append("aplica", 1);}
        else {formData.append("aplica", 0)}
        if(data.aplica_calculo) {formData.append("aplica_calculo", 1);}
        else {formData.append("aplica_calculo", 0)}
        formData.append("tipo", data.tipo);
        axios.post(API_URL + `/segmento/${data.id}`, formData).then(res => {
            hideES();
            Toaster("success", res.data.message);
            setBuffer(false)
        })
    }

    const EditPunto = (data,e) => {
        setBuffer(true)
        e.preventDefault()
        let headers = setHeaders();
        axios.post(API_URL + '/sucursal/updateCat', data, {headers} ).then(res =>{
            Toaster("success", res.data.message)
            hideEdit()
            setBuffer(false)
            reset({id: null,nombre_sucursal : null,estado: null});
        })
    }
    const getSegmento = (segmentoId) => {        
        if(segmentoId !== null && segmentoId !== undefined && segmentos.find(s => s.id === Number(segmentoId)) !== undefined)
        { return segmentos.find(s => s.id === Number(segmentoId)).nombre_segmento }
        else return "N/A"
    }
    return(<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
     <Modal show={segView} onHide={hideSegmento}>
     <Modal.Header closeButton>
          <Modal.Title>Nuevo Segmento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(createSegmento)}>
            <Modal.Body>
                <Form.Control type="hidden" value={logData.id} {...register("client_id")} />
                <Form.Control type="hidden" value={"punto"} {...register("tipo")} />
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre del segmento</Form.Label>
                        <Form.Control placeholder="Nombre" {...register("nombre_segmento")}/>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
               <Form.Group className="mb-2">
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control size="sm" as="textarea" rows={3} {...register("descripcion")} />
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group> 
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label> Aplica calculo?</Form.Label>
                        <Form.Check type="checkbox" defaultChecked {...register("aplica_calculo")} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Peso (%)</Form.Label>
                        <Form.Control size="sm" {...register("peso")}/>
                        <Form.Text className="text-muted">Peso sobrante (100-peso)</Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label> Aplica peso?</Form.Label>
                        <Form.Check type="checkbox" {...register("aplica")}/>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideSegmento}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={loadBuffer}>
                Crear
            </Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <Modal show={segEdit} onHide={hideES}>
     <Modal.Header closeButton>
          <Modal.Title>Editar Segmento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(editSegmento)}>
            <Modal.Body>
                <Form.Control type="hidden" value={logData.id} {...register("client_id")} />
                <Form.Control type="hidden" value={"punto"} {...register("tipo")} />
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre del segmento</Form.Label>
                        <Form.Control placeholder="Nombre" {...register("nombre_segmento")}/>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
               <Form.Group className="mb-2">
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control size="sm" as="textarea" rows={3} {...register("descripcion")} />
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group> 
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label> Aplica calculo?</Form.Label>
                        <Form.Check type="checkbox" defaultChecked {...register("aplica_calculo")} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Peso (%)</Form.Label>
                        <Form.Control size="sm" {...register("peso")}/>
                        <Form.Text className="text-muted">Peso sobrante (100-peso)</Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label> Aplica peso?</Form.Label>
                        <Form.Check type="checkbox" {...register("aplica")}/>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideES}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={loadBuffer}>
                Actualizar
            </Button>
            </Modal.Footer>
        </Form>
    </Modal>

    <Modal show={editView} onHide={hideEdit}>
    <Modal.Header closeButton>
          <Modal.Title>Asignar segmento de punto</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(EditPunto)}>
            <Modal.Body>
                <Form.Control type="hidden" value={logData.id} {...register("client_id")}/>
                <Form.Group className="mb-2">
                    <Form.Label>nombre de punto</Form.Label>
                    <Form.Control plaintext {...register("nombre_sucursal")} />
                </Form.Group>
                <Form.Select {...register("segmento")}>
                    <option value={null}>Segmentos disponibles...</option>
                    {segmentos.map((segmento) => <option value={segmento.id}>{segmento.nombre_segmento}</option>)}
                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideEdit}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={loadBuffer}>
                Actualizar
            </Button>
            </Modal.Footer>
        </Form>
    </Modal>
        <div className="content-container">
        <Form>
            <h3 className="mb-4">Segmentación de puntos</h3>
            <Row className="mb-2">
                <Form.Group as={Col}>
                    <Form.Select onChange={handleChange}>
                        <option value={null}>Sin Filtro</option>
                        {segmentos.map((segmento) => <option value={segmento.id}>{segmento.nombre_segmento}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label><h3> </h3></Form.Label>
                    <Button className="App-button" onClick={showSegmento}>nuevo segmento</Button>
                </Form.Group>
            </Row>
        </Form>
          <div className="content-body-alt">
          <Table className="App-Table" striped responsive="md" hover size="sm">
            <thead>
                <tr>
                    <th className="table-th">Segmento</th>
                    <th className="table-th">descripcion</th>
                    <th className="table-th">Aplica Calculo ?</th>
                    <th className="table-th">estado</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {segmentos.map((segmento) => 
                    <tr>
                        <td>{segmento.nombre_segmento}</td>
                        <td>{segmento.descripcion}</td>
                        <td>{segmento.aplica_calculo > 0 ? "si" : "no"}</td>
                        <td>{segmento.estado}</td>
                        <td className="clickable-end"><BiEditAlt onClick={() => showES(segmento)}/></td>
                    </tr>
                )}
            </tbody>
          </Table>
            {/*filter by sub segmento, segmento and none */}
          <Table className="App-Table" striped responsive="md" hover size="sm">
              <thead>
                  <tr>
                      <th className="table-th">nombre de punto</th>
                      <th className="table-th">segmento</th>                        
                      <th></th>                        
                  </tr>
              </thead>
              <tbody>
                      {FP.map((punto) => 
                         <tr>
                              <td>{punto.nombre_sucursal}</td>
                              <td>{getSegmento(punto.segmento)} </td>
                              <td className="clickable-end" onClick={() => showEdit(punto)}><BiEditAlt /></td>
                          </tr>
                      )}
              </tbody>
          </Table>
  </div>
  <div className="flex-between">
        <Button className="App-button" onClick={() => {newLocation("../puntos")}}>Atrás</Button>
        
        </div>
        </div>
    </>)
}