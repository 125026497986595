import { useForm } from "react-hook-form"
import { useState, useContext, useEffect } from "react";
import {Form, Button, Toast, ToastContainer} from "react-bootstrap"
import axios from "axios"
import { BiEditAlt } from "react-icons/bi";
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

export default function Metodologia() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const {logData} = useContext(ClientContext);
    const {userData} = useContext(UserContext);
    const [metodologia, setMet] = useState("")
    const [edit, setEdit] = useState(false)
    const [Temp, setTemp] = useState()
    const [id, setID] = useState()
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
 
const updateMetodologia = (datax,e) => {
    e.preventDefault()
        datax.id = id;
        datax.client_id = Temp;
        datax.metodologia = metodologia;
    axios.post(API_URL + '/metodologia', datax).then(
        function (response) {Toaster("success", response.data.message); setEdit(false)})
}

function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

useEffect(() => {
    if(userData.permiso === 'admin')
    {   setTemp(logData.id)
        axios.get(API_URL + '/metodologia', {params: {client_id:logData.id}}).then(res => {
            if(res.data.length > 0){
                setMet(res.data[0].metodologia)
                setID(res.data[0].id)
            }
        })
    }
    else {
        setTemp(userData.cliente_id)
        axios.get(API_URL + '/metodologia', {params: {client_id:userData.cliente_id}}).then(res => {
            if(res.data.length > 0){
                setMet(res.data[0].metodologia)
                setID(res.data[0].id)
            }
        })
    }
}, [toast])

return(<>
<ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
<div className="content-container">
    <div className="content-body-alt p-2">
        <h5>Metodologia</h5>
        { !edit ? <div className="Banner mb-3">
            <BiEditAlt className="floating-edit" onClick={() => setEdit(true)}/>
            {parse(metodologia)}
        </div>:
        <Form onSubmit={handleSubmit(updateMetodologia)}>    
            <Form.Group className="Banner mb-2 pt-2">       
              <ReactQuill  className="sub-banner mt-2" theme="snow" value={metodologia} onChange={setMet} />     
            </Form.Group>
            <Button type="submit" className="App-button">
                Guardar
            </Button>
        </Form>}
    </div>
</div>
    </>)
}