
import ClientContext from "../../../Context/ClientContext";
import axios from "axios";
import { useState, useContext, useEffect } from "react"
import { Form, Button, Table, Toast, ToastContainer, InputGroup, Modal } from "react-bootstrap";
import { BiEditAlt, BiDownload, BiTrash } from "react-icons/bi";
import * as FileSaver from 'file-saver';
import { useForm } from "react-hook-form";

export default function EvaluarPlan() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/app/public'    
    const { logData } = useContext(ClientContext)
    const re = /(?:\.([^.]+))?$/;
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const [planes, setPA] = useState([])    
    const [puntos, setPuntos] = useState([])
    const [viewDelete, setDelete] = useState(false)
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onBlur" });

    const UpdateData = (data, e) => {
        e.preventDefault();
        let headers = setHeaders()
        axios.post(API_URL + '/plan/evaluation', planes, {headers}).then(function (response) {
            Toaster("success", response.data.message)
        })
    }

    useEffect(() => {
        axios.get(API_URL + '/plan', { params: { client_id: logData.id } }).then(
            function (response) {
                response.data.sort(function (a, b) {
                    return new Date(a.plan_inicio) - new Date(b.plan_inicio); //ordenar por fecha.
                });
                setPA(response.data)
            })
        axios.get(API_URL + '/sucursal', { params: {cliente_id:logData.id} }).then(
            (res) => {
                res.data.forEach(p => {
                    p.label = p.nombre_sucursal;
                    p.value = p.id;
                })
                setPuntos(res.data)})
    }, [toast])

    function Toaster(variant, message) { setToast({ show: true, variant: variant, message: message }); }

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
        return headers;
    }

    const showDelete = (pa) => {
        setDelete(true)
        reset({ id: pa.id })
    }
    const hideDelete = () => { setDelete(false) }

    const RemovePlan = (data, e) => {
        e.preventDefault()
        let headers = setHeaders()
        axios.delete(API_URL + `/plan/${data.id}`, { headers }).then(res => {
            Toaster("success", res.data.message)
            hideDelete()
        })
    }

    function setVal(pa,e) {
        let list = planes;
        list.find(p => p.id === pa.id).cumple = e
        setPA(list)
     }
 
    return (<>
        <ToastContainer style={{ position: "fixed", top: "80vh", right: "0vw" }} className="p-3">
            <Toast
                bg={toast.variant}
                onClose={() => setToast({ show: false, variant: "", message: "" })}
                show={toast.show}
                delay={2000}
                autohide>
                <Toast.Header>
                    <strong className="me-auto">Medicion</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body className="text-white">{toast.message}</Toast.Body>
            </Toast>
        </ToastContainer>
        <Modal show={viewDelete} onHide={hideDelete}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Plan</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(RemovePlan)}>
                <Modal.Body>
                    <h4>Esta seguro ?</h4>
                    <h5>esta acción no puede deshacerse</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="App-button-alt" onClick={hideDelete}>Cerrar</Button>
                    <Button type="submit" className="App-button">Eliminar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
        <div className="content-container">
            <div className="content-body-alt mb-4">
                <h3 className="p-2">Planes de accion</h3>
                <Form onSubmit={handleSubmit(UpdateData)}>
                    <Table className="App-Table" striped responsive="md" hover size="sm">
                        <thead>
                            <tr>
                                <th className="table-th">Plan de accion</th>
                                <th className="table-th">Sucursal</th>
                                <th className="table-th">Fecha inicio</th>
                                <th className="table-th">Fecha final</th>
                                <th className="table-th">Ayudas digitales</th>
                                <th className="table-th">Cumple</th>
                                <th className="table-th">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {planes.map((pa,index) =>
                                <tr>
                                    <td>{pa.nombre_plan}</td>
                                    <td>{pa.punto_id && puntos.length > 0 ? puntos.find(p => { return p.id === pa.punto_id }).nombre_sucursal : null}</td>
                                    <td>{pa.plan_inicio}</td>
                                    <td>{pa.plan_fin}</td>
                                    <td className="clickable-table" onClick={() => { FileSaver.saveAs(Image_URL + pa.ayudas, "ayuda." + re.exec(pa.ayudas)[0]); }}><BiDownload /></td>
                                    <td style={{ textAlign: "center" }}>
                                        <InputGroup onClick={(e) => {
                                            for(let i = 0; i < e.target.parentNode.children.length; i++) {
                                                e.target.parentNode.parentNode.children[i].children[0].checked = false
                                            }
                                            e.target.checked = true
                                        }} >                                                                        
                                            <Form.Check className="mt-0 mb-0 m-1" onClick={() => setVal(pa, 1)} label="si" defaultChecked = {pa.cumple === 1 ? true : false} ></Form.Check>                                    
                                            <Form.Check className="mt-0 mb-0 m-1" onClick={() => setVal(pa, 0)} label="no" defaultChecked = {pa.cumple === 0 ? true : false} ></Form.Check>
                                        </InputGroup>
                                    </td>
                                    <td className="clickable-end" onClick={() => showDelete(pa)}><BiTrash /></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Button type="submit" className="m-2 App-button" >Actualizar datos</Button>
                </Form>
            </div>
        </div>
    </>)
}