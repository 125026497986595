import { useState, useContext, useEffect } from "react";
import { Form, Button, Table, Row, Col} from "react-bootstrap";
import { set, useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import ParametrosContext from "../../../Context/ParametrosContext";
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";

export default function ReportShop() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [consulta, setConsulta] = useState(false)

    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()

    const [sPuntos, setSPtos] = useState([])
    const [sSecciones, setSS] = useState([])    
    const [sPeriod, setP] = useState({})
    
    const [respuestas, setR] = useState([])
    const [dataFiltered, setDF] = useState()

    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])
        
    let example = [{
        Reporte_id: 844,
        Encuesta_id: 13,
        Punto_id : 2,
        Nombre_sucursal: "Punto",
        Periodo_id: 25,                        
        Value : 80 }]
    
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    useEffect(()=>{
        axios.get(API_URL+'/respuesta', {params: { client_id:logData.id}}).then(res=>{
            setR(res.data)
        })
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(
            function(response) {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then(
            (res) => {
                res.data.forEach(p => {
                    p.label = p.nombre_sucursal;
                    p.value = p.id;
                })
                setPuntos(res.data)})
        axios.get(API_URL + '/seccion/report', { params: { client_id:logData.id } }).then(
            function (response) {
                response.data.forEach(p => {
                    p.label = p.nombre_seccion;
                    p.value = p.id;
                })
                setSecciones(response.data)})
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id} }).then(
            function(response) {
            let n = response.data.filter(encuesta => encuesta.punto_encuesta !== null);
            setEncuestas(n)})
    },[])

    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        let Document = dataFiltered
        Document.forEach((d) => {
            delete d.tipo_pregunta;
            delete d.punto_id;
            delete d.periodo_id;
        })
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }

    function getOccurrence(array, value) {return respuestas.filter((v) => (v.reporte_id === value)).length;}

    function Tabulada(){
        let data; 
        let sum = []
        let res = respuestas
        if(userData.permiso === "admin") {
            res = respuestas
        }
        else {
            res = respuestas.filter(el => {
                return respuestas.some(r=>{
                    return userData.puntos.split(",").indexOf(r.punto_id.toString()) > -1 || userData.secciones.split(",").indexOf(r.seccion_id.toString()) >-1 })
            })
        }
        res.forEach((r)=>{
            if(r.tipo_pregunta === "audit") { //verificar valores.
                if(r.respuesta === "ok") {
                    data = {
                        Respuesta_id: r.id,
                        Value :100,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                    }
                    sum.push(data)
                }
                else {
                    data = {
                        Respuesta_id: r.id,
                        Value : r.respuesta,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                    }
                    sum.push(data)
                }
            }
        })
        return sum;
      }

    function showData(filtered) {
        let helper = []        
        filtered.forEach(v => {
            const bat = getOccurrence(filtered, v.Reporte_id)
            if (bat > 1) {
            const indet = helper.findIndex(o => o.Reporte_id === v.Reporte_id && o.Pregunta === v.Pregunta)
            if(indet === -1) {
                let temp = filtered.filter((q) => (q.Reporte_id === v.Reporte_id && q.Pregunta === v.Pregunta))
                var result = {
                    Reporte_id: v.Reporte_id,
                    Encuesta_id: v.Encuesta_id,
                    Punto_id : v.Punto_id,
                    Nombre_sucursal: puntos.find(p => p.id === v.Punto_id).nombre_sucursal,
                    Periodo_id: v.Periodo_id,
                    Seccion_id : v.Seccion_id,
                    Pregunta : v.Pregunta,
                    Video_id: v.Video_id,
                    Value : (temp.reduce(function (acc, obj) { return acc + obj.Value; }, 0)/temp.length).toFixed(2) }
                helper.push(result)
             }
           }
           else { 
            var result = {
            Reporte_id: v.Reporte_id,
            Encuesta_id: v.Encuesta_id,
            Punto_id : v.Punto_id,
            Nombre_sucursal: puntos.find(p => p.id === v.Punto_id).nombre_sucursal,
            Periodo_id: v.Periodo_id,
            Seccion_id : v.Seccion_id,
            Pregunta : v.Pregunta,
            Video_id: v.Video_id,
            Value : v.Value }
            helper.push(result)}
        })
        setDF(helper)
        setConsulta(true)
    }
    
    const SetFilters = (data,e) => {
        let tab = Tabulada()
        let per =  periodos.find(el => el.id === Number(data.periodo))        

        e.preventDefault();
        if(!enc) {setConsulta(false);return;}
        let temp = []
        temp =  tab.filter(el => {
            return sPuntos.some(p => {
                return sSecciones.some(s => {                   
                    return p.id === el.Punto_id && 
                    s.id === el.Seccion_id && 
                    per.id === el.Periodo_id && 
                    el.Encuesta_id === enc.id
                })                
            })
        })
        
        setP(per)
        showData(temp)
    }
    
    const handleEncuesta = (e) => {
        e.preventDefault()
        setSS([])
        let sec = []
        if(userData.permiso === "admin")   
        { sec = secciones}
        else {
          sec = secciones.filter(el=>{ return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
        }
        let enc = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => {return s.encuesta_id === Number(e.target.value)})
        setE(enc)
        setS(tem)
    }   

    return (<>
         <div className="content-container">
    <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">
                            <Col>
                                <Form.Select {...register("periodo")}>
                                    <option value={null}>Sin Periodo Seleccionado</option>
                                    {periodos.map(per => {return <option value={per.id}>{per.nombre_periodo}</option>})}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Select onChange={handleEncuesta}>
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>})}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todas las Secciones Seleccionadas.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",}
                                  }
                                 options={Secciones}
                                 value={sSecciones}
                                 onChange={setSS}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Puntos",
                                    "create": "Create",}
                                  }
                                 options={userData.permiso === "admin" ?
                                 puntos :
                                 puntos.filter(el => { return  userData.puntos.split(",").indexOf(el.id.toString()) > -1 })}
                                 value={sPuntos}
                                 onChange={setSPtos}
                                 labelledBy="Select"
                                />
                            </Col>
                        </Row>
                        <Row className="m-1 mt-3">
                            <Button type="submit" className="App-button m-2 mt-0">Aplicar filtros <BiSearchAlt/></Button>
                        </Row>
    </Form>
    {consulta ? 
    <div className="mb-4">
        <Row className="row-excel-button">
            <h3 className="p-2" style={{width:'50vw'}}>Resultados de reportes de Precios</h3>
            <Button size="sm" className="App-button" onClick={exportToExcel}>Descargar Excel</Button>    
        </Row>
        <Table className="App-Table" striped responsive="md" hover size="sm">
            <thead>
                <tr>
                    <th className="table-th">Sucursal</th>
                    <th className="table-th">Periodo</th>
                    <th className="table-th">Encuesta</th>
                    <th className="table-th">Resultado</th>                                                
                </tr>
            </thead>
            <tbody>
                {example.map(dt => {
                    return <tr>
                        <td>{dt.Nombre_sucursal}</td>
                        <td>{sPeriod.nombre_periodo}</td>
                        <td>{enc.nombre_encuesta}</td>
                        <td>{dt.Value+"%"}</td>
                    </tr>
                })}
            </tbody>
        </Table>
        </div>: null}
    </div>
    </>)
}