import ClientContext from "../../../Context/ClientContext";
import axios from "axios";
import {useState,useContext,useEffect} from "react"
import { Row, Col, Form, Button, Table, Toast, ToastContainer,} from "react-bootstrap";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'
import { BiSearchAlt } from 'react-icons/bi'
import { BarLoader } from "react-spinners"
import { useForm } from "react-hook-form";
import {format } from 'date-fns';

export default function Registry() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const {logData} = useContext(ClientContext)    
    const [users, setUsers] = useState([])
    const [registry, setRegs] = useState([])
    const [FR, setFR] = useState([])
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });    
    const [loader, setLoad] = useState(false)
    const [consulta, setConsulta] = useState(false)
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
      } = useForm({ mode: "onBlur" });    
    
    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        let Document = registry //data de planes
        Document.forEach(d => {
            d.usuario = users.find(u => {return Number(u.id) === Number(d.user_id)}).usuario
        })
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }

    const getDateshort = (date) => {                
        const formattedDate = format(date, 'dd/MM-H:mm');            
        return formattedDate        
    }

    const SetFilters = () => {
        setConsulta(false)
        setLoad(true)
        let values = getValues()                
        let init = new Date(values.date_init.replace(/-/g, '\/').replace(/T.+/, ''))
        let end = new Date(values.date_fin.replace(/-/g, '\/').replace(/T.+/, ''))   

        let reps = registry.filter(r => {                        
            if ((new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, '')).getTime() >= init.getTime()) 
              && (new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, '')).getTime() <= end.getTime())) 
            { return r }                                
          })                            
        if(init.toString() !== "Invalid Date" && end.toString() !== "Invalid Date") {            
            setFR(reps)
            setConsulta(true)
        }        
        setTimeout(() => {
            setLoad(false)
        },[1000])
    }

    useEffect(() => {       
        axios.get(API_URL + '/usuario', {params: {cliente_id:logData.id}}).then(res => { setUsers(res.data) })
        axios.get(API_URL+"/registry", {params: {client_id:logData.id }}).then(res => { setRegs(res.data) })        
            },[toast])

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}    

    return (<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <div className="content-container ">
    <Form onSubmit={handleSubmit(SetFilters)}>                        
                        <Row>
                            <Col>
                                <Form.Label className='m-2 mt-1 mb-1'>Fecha inicio</Form.Label>
                                <Form.Control className='rmsc' defaultValue={getValues("date_init")} type='date' {...register("date_init")} />
                            </Col>
                            <Col>
                                <Form.Label className='m-2 mt-1 mb-1'>Fecha fin</Form.Label>
                                <Form.Control className='rmsc' defaultValue={getValues("date_fin")} type='date' {...register("date_fin")} />
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row className="m-1 mt-3">
                            <Button type="submit" className="App-button m-2 mt-0" disabled = {loader}>
                                {loader ? <BarLoader className="m-2" color="#FFF" width={100} />:<>Aplicar filtros <BiSearchAlt/></> }
                            </Button>
                        </Row>
    </Form>
        {consulta ? <div className="content-body-alt mb-4 ">
            <h3 className="p-2">Tracking de ingreso de usuarios</h3>    
            <h4>{logData.nombre_cliente}</h4>        
            <Form onSubmit={handleSubmit(consulta)}>
            <Table className="App-Table max-height-50" striped responsive="md" hover size="sm">
                    <thead>                        
                        <tr>
                            <th className="table-th">Usuario</th>
                            <th className="table-th">N° de ingresos</th>
                            <th className="table-th">ultimo ingreso</th>
                        </tr>
                    </thead>
                    <tbody>                   
                        {users.map(us => {
                            let size = FR.filter(r => {return Number(us.id) === Number(r.user_id)}).length                            
                            return <tr>
                                <td>{us.usuario}</td>
                                <td className="text-center">{ size }</td>
                                <td className="text-center">{FR.filter(r => {return Number(us.id) === Number(r.user_id)})[size-1] ? getDateshort(FR.filter(r => {return Number(us.id) === Number(r.user_id)})[size-1].created_at) : "NA" }</td>
                            </tr>
                        })}                                     
                    </tbody>
                </Table>
            </Form>
            <Button type="submit" className="m-2 App-button" onClick={exportToExcel}>Descargar Excel</Button>
        </div>: null}
    </div>
    </>)
}