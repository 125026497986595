import { useState, useContext, useEffect } from "react";
import { Form, Button, Table, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import ParametrosContext from "../../../Context/ParametrosContext";
import ClientContext from "../../../Context/ClientContext";
import { BarLoader } from "react-spinners";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";
import UserContext from "../../../Context/UserContext";
import YearFilter from "../../shared/YearFilter";

export default function ReporteSecciones() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const { logData } = useContext(ClientContext)
    const { paramData } = useContext(ParametrosContext);
    const { userData } = useContext(UserContext)
    const [consulta, setConsulta] = useState(false)

    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()

    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [sSecciones, setSS] = useState([])

    const [selectedYear, setSelectedYear] = useState(null)
    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])

    const [respuestas, setR] = useState([])
    const [dataFiltered, setDF] = useState([])
    const [FS, setFS] = useState([])
    const [PS, setPS] = useState([])
    const [cities, setCities] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.
    const [Basencuestas, setBaseEncuestas] = useState([])
    const [joints, setJoints] = useState([])
    const [loader, setLoad] = useState(false)
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onBlur" });

    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        let Document = dataFiltered
        Document.forEach((d) => {
            delete d.tipo_pregunta;
            delete d.punto_id;
            delete d.periodo_id;
        })
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }
    // Carga de periodos basados en `selectedYear`
    useEffect(() => {
        if (!selectedYear) return;

        axios.get(`${API_URL}/periodo`, { params: { client_id: logData.id, year: selectedYear } })
            .then((response) => {
                const periodos = response.data
                    .map((p) => ({
                        ...p,
                        label: p.nombre_periodo,
                        value: p.id,
                    }))
                    .sort((a, b) => new Date(a.periodo_inicio) - new Date(b.periodo_inicio));
                setPeriodos(periodos);
            })
            .catch((error) => console.error("Error al cargar periodos:", error));
    }, [selectedYear, logData.id]); // Se ejecuta cuando `selectedYear` cambia

    useEffect(() => {
        axios.get(API_URL + '/joint', { params: { client_id: Number(logData.id) } }).then(res => { setJoints(res.data) })
        axios.get(API_URL + '/ciudad', { params: { client_id: logData.id } }).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        axios.get(API_URL + '/sucursal', { params: { cliente_id: logData.id } }).then((res) => {
            res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })
            if (logData.id === 49) { setPuntos(res.data) }
            else { setSuc(res.data) }
        })
        axios.get(API_URL + '/seccion/report', { params: { client_id: logData.id } }).then((response) => {
            response.data.forEach(p => {
                p.label = p.nombre_seccion;
                p.value = p.id;
            })
            setSecciones(response.data)
        })
        axios.get(API_URL + '/encuesta/all', { params: { client_id: logData.id } }).then((response) => {
            let n;
            let temp;
            if (typeof response.data === 'object') { temp = Object.keys(response.data).map((key) => response.data[key]); }
            else { temp = response.data }
            if (userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null); }
            else { let vat = temp.filter(el => { return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null); }
            setBaseEncuestas(n)
        })
    }, [])



    function getOccurrence(array, value) { return respuestas.filter((v) => (v.seccion_id === value)).length; }

    function showData(filtered) {
        let helper = []
        filtered.forEach(v => {
            const bat = getOccurrence(filtered, v.Seccion_id)
            if (bat > 1) {
                const indet = helper.findIndex(o => o.Punto_id === v.Punto_id && o.Seccion_id === v.Seccion_id && o.Periodo_id === v.Periodo_id)
                if (indet === -1) {
                    let temp = filtered.filter((q) => (q.Punto_id === v.Punto_id && q.Seccion_id === v.Seccion_id && q.Periodo_id === v.Periodo_id))
                    var result = {
                        Reporte: v.Reporte,
                        Encuesta_id: v.Encuesta_id,
                        Encuesta: v.Encuesta,
                        Nombre_sucursal: Sucursales.find(p => p.id === v.Punto_id).nombre_sucursal,
                        Punto_id: v.Punto_id,
                        Periodo_id: v.Periodo_id,
                        Seccion: v.Seccion,
                        Seccion_id: v.Seccion_id,
                        Value: (temp.reduce(function (acc, obj) { return acc + obj.Value; }, 0) / temp.length).toFixed(2)
                    }
                    helper.push(result)
                }
            }
            else {
                var result = {
                    Reporte_id: v.Reporte_id,
                    Encuesta_id: v.Encuesta_id,
                    Nombre_sucursal: Sucursales.find(p => p.id === v.Punto_id).nombre_sucursal,
                    Punto_id: v.Punto_id,
                    Periodo_id: v.Periodo_id,
                    Seccion_id: v.Seccion_id,
                    Value: v.Value,
                }
                helper.push(result)
            }
        })
        setDF(helper)
        setConsulta(true)
    }

    function getClass(val) {
        let x = Number(val)
        if (x < paramData.values.amarillo) { return "Fondo-R" }
        if (x < paramData.values.verde) { return "Fondo-A" }
        if (x >= paramData.values.verde) { return "Fondo-V" }
    }

    const SetFilters = (data, e) => {
        let tab = Tabulada()
        e.preventDefault();
        if (!enc) { setConsulta(false); return; }
        let temp = []
        temp = tab.filter(el => {
            return sPuntos.some(p => {
                return sSecciones.some(s => {
                    return sPeriods.some(f => {
                        return p.id === el.Punto_id &&
                            s.id === el.Seccion_id &&
                            f.id === el.Periodo_id &&
                            el.Encuesta_id === enc.id
                    })
                })
            })
        })
        setPS(sPeriods)
        setFS(sSecciones)
        showData(temp)
    }

    function Tabulada() {
        let data;
        let sum = []
        let res = respuestas
        if (userData.permiso === "admin") {
            res = respuestas
        }
        else {
            res = respuestas.filter(el => {
                return userData.puntos.split(",").indexOf(el.punto_id.toString()) > -1 || userData.secciones.split(",").indexOf(el.seccion_id.toString()) > -1
            })
        }
        res.filter(r => r.encuesta_id === enc.id)
            .forEach((r) => {
                if (r.tipo_pregunta === 'escala-tri') {
                    if (r.respuesta === 'malo') {
                        data = {
                            Respuesta_id: r.id,
                            Value: 0,
                            Reporte_id: r.reporte_id,
                            Punto_id: r.punto_id,
                            Periodo_id: r.periodo_id,
                            Encuesta_id: r.encuesta_id,
                            Seccion_id: r.seccion_id,
                            Pregunta: r.pregunta,
                            Tipo_pregunta: r.tipo_pregunta,
                            Video_id: r.video_id
                        }
                        sum.push(data)
                    }
                    if (r.respuesta === 'regular') {
                        data = {
                            Respuesta_id: r.id,
                            Value: 50,
                            Reporte_id: r.reporte_id,
                            Punto_id: r.punto_id,
                            Periodo_id: r.periodo_id,
                            Encuesta_id: r.encuesta_id,
                            Seccion_id: r.seccion_id,
                            Pregunta: r.pregunta,
                            Tipo_pregunta: r.tipo_pregunta,
                            Video_id: r.video_id
                        }
                        sum.push(data)
                    }
                    if (r.respuesta === 'excelente') {
                        data = {
                            Respuesta_id: r.id,
                            Value: 100,
                            Reporte_id: r.reporte_id,
                            Punto_id: r.punto_id,
                            Periodo_id: r.periodo_id,
                            Encuesta_id: r.encuesta_id,
                            Seccion_id: r.seccion_id,
                            Pregunta: r.pregunta,
                            Tipo_pregunta: r.tipo_pregunta,
                            Video_id: r.video_id
                        }
                        sum.push(data)
                    }
                }
                if (r.tipo_pregunta === "escala") {
                    data = {
                        Respuesta_id: r.id,
                        Value: (r.respuesta * 100) / r.valor_max,
                        Reporte_id: r.reporte_id,
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id,
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id: r.video_id
                    }
                    sum.push(data)
                }
                if (r.tipo_pregunta === "thumbs") {
                    if (r.respuesta === "si") {
                        data = {
                            Respuesta_id: r.id,
                            Value: 100,
                            Reporte_id: r.reporte_id,
                            Punto_id: r.punto_id,
                            Periodo_id: r.periodo_id,
                            Encuesta_id: r.encuesta_id,
                            Seccion_id: r.seccion_id,
                            Pregunta: r.pregunta,
                            Tipo_pregunta: r.tipo_pregunta,
                            Video_id: r.video_id
                        }
                        sum.push(data)
                    }
                    else if (r.respuesta === "no") {
                        data = {
                            Respuesta_id: r.id,
                            Value: 0,
                            Reporte_id: r.reporte_id,
                            Punto_id: r.punto_id,
                            Periodo_id: r.periodo_id,
                            Encuesta_id: r.encuesta_id,
                            Seccion_id: r.seccion_id,
                            Pregunta: r.pregunta,
                            Tipo_pregunta: r.tipo_pregunta,
                            Video_id: r.video_id
                        }
                        sum.push(data)
                    }
                }
                if (r.tipo_pregunta === "stars") {
                    data = {
                        Respuesta_id: r.id,
                        Value: (r.respuesta * 100) / 5,
                        Reporte_id: r.reporte_id,
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id,
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id: r.video_id
                    }
                    sum.push(data)
                }
                if (r.tipo_pregunta === "nps" || (r.tipo_pregunta === "npsna" && r.respuesta !== "na")) {
                    data = {
                        Respuesta_id: r.id,
                        Value: (r.respuesta * 100) / 10,
                        Reporte_id: r.reporte_id,
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id,
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id: r.video_id
                    }
                    sum.push(data)
                }
                if (r.tipo_pregunta === "smiles") {
                    data = {
                        Respuesta_id: r.id,
                        Value: (r.respuesta * 100) / 5,
                        Reporte_id: r.reporte_id,
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id,
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id: r.video_id
                    }
                    sum.push(data)
                }
                if (r.tipo_pregunta === "audit") {
                    if (r.respuesta === "si") {
                        data = {
                            Respuesta_id: r.id,
                            Value: 100,
                            Reporte_id: r.reporte_id,
                            Punto_id: r.punto_id,
                            Periodo_id: r.periodo_id,
                            Encuesta_id: r.encuesta_id,
                            Seccion_id: r.seccion_id,
                            Pregunta: r.pregunta,
                            Tipo_pregunta: r.tipo_pregunta,
                            Video_id: r.video_id
                        }
                        sum.push(data)
                    }
                    else if (r.respuesta === "no") {
                        data = {
                            Respuesta_id: r.id,
                            Value: 0,
                            Reporte_id: r.reporte_id,
                            Punto_id: r.punto_id,
                            Periodo_id: r.periodo_id,
                            Encuesta_id: r.encuesta_id,
                            Seccion_id: r.seccion_id,
                            Pregunta: r.pregunta,
                            Tipo_pregunta: r.tipo_pregunta,
                            Video_id: r.video_id
                        }
                        sum.push(data)
                    }
                }
            })
        return sum;
    }

    const handleEncuesta = (e) => {
        setLoad(true)
        e.preventDefault()
        setSS([])
        let sec = []
        if (userData.permiso === "admin") { sec = secciones }
        else {
            sec = secciones.filter(el => { return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
        }
        let enc = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => { return s.encuesta_id === Number(e.target.value) })
        if (enc) {
            axios.get(API_URL + '/respuesta', { params: { encuesta_id: Number(e.target.value), periodo_id: sPeriods } }).then(res => {
                let temp = res.data
                let temp2 = []
                axios.get(API_URL + '/video', { params: { client_id: logData.id } }).then((response) => {
                    let videos = response.data.filter(v => { return v.estado !== "inactivo" })
                    if (userData.permiso === "admin" || userData.permiso === "gerente") { temp2 = temp; }
                    else {
                        let tetemp = temp.filter(el => { return userData.encuestas.split(",").some(s => { return Number(el.encuesta_id) === Number(s) }) })
                        temp2 = tetemp.filter(p => { return videos.some(v => { return (p.video_id === v.id || p.video_id === null) }) });
                    }
                    setR(temp2); setLoad(false)
                })
            })
            setE(enc)
            setS(tem)
        }
    }
    const handleCity = (e) => {
        e.preventDefault()
        setSPtos([])
        let sucursales = puntos.filter(s => { return Number(s.ciudad_id) === Number(e.target.value) })
        setSuc(sucursales)
    }
    const handlePerdiod = (e) => {
        setEncuestas([])
        if (sPeriods.length > 0) {
            let temp = joints.filter(j => { return sPeriods.some(p => { return Number(p.id) === Number(j.periodo_id) }) })
            let temp2 = Basencuestas.filter(e => { return temp.some(j => { return Number(j.encuesta_id) === Number(e.id) }) })
            setEncuestas(temp2)
        }
    }
    return (<>
        <div className="content-container">
            <Form onSubmit={handleSubmit(SetFilters)}>
                <Row className="mb-1">
                    <Col>
                        <YearFilter selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
                    </Col>
                    <Col>
                        <MultiSelect
                            overrideStrings={{
                                "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                                "clearSearch": "Limpiar busqueda",
                                "clearSelected": "Limpiar Seleccion",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Todos",
                                "selectAllFiltered": "Todos (Filtrado)",
                                "selectSomeItems": "Seleccionar Periodo",
                                "create": "Create",
                            }
                            }
                            options={periodos}
                            value={sPeriods}
                            onChange={setSP}
                            onMenuToggle={handlePerdiod}
                            labelledBy="Select"
                        />
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col>
                        <Form.Select onClick={handleEncuesta}>
                            <option value={null}>Sin Encuesta Seleccionada</option>
                            {encuestas.length > 0 ? encuestas.map(enc => { return <option value={enc.id}>{enc.nombre_encuesta}</option> }) : null}
                        </Form.Select>
                    </Col>
                    <Col>
                        {logData.id === 49 ?
                            <Form.Select onChange={handleCity} >
                                <option value={null}>Sin Ciudad Seleccionada</option>
                                {cities.length > 0 ? cities.map(enc => { return <option value={enc.id}>{enc.nombre_ciudad}</option> }) : null}
                            </Form.Select>
                            : <MultiSelect
                                overrideStrings={{
                                    "allItemsAreSelected": "Todo Seleccionado.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",
                                }
                                }
                                options={Secciones}
                                value={sSecciones}
                                onChange={setSS}
                                labelledBy="Select"
                            />}
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <MultiSelect
                            overrideStrings={{
                                "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                                "clearSearch": "Limpiar busqueda",
                                "clearSelected": "Limpiar Seleccion",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Todos",
                                "selectAllFiltered": "Todos (Filtrado)",
                                "selectSomeItems": "Seleccionar Puntos",
                                "create": "Create",
                            }
                            }
                            options={userData.permiso === "admin" ?
                                Sucursales :
                                Sucursales.filter(el => {
                                    return userData.puntos.split(",").indexOf(el.id.toString()) > -1
                                })}
                            value={sPuntos}
                            onChange={setSPtos}
                            labelledBy="Select"
                        />
                    </Col>
                </Row>
                {logData.id === 49 ? <Row className="mb-1">
                    <Col>
                        <MultiSelect
                            overrideStrings={{
                                "allItemsAreSelected": "Todo Seleccionado.",
                                "clearSearch": "Limpiar busqueda",
                                "clearSelected": "Limpiar Seleccion",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Todos",
                                "selectAllFiltered": "Todos (Filtrado)",
                                "selectSomeItems": "Seleccionar Secciones",
                                "create": "Create",
                            }
                            }
                            options={Secciones}
                            value={sSecciones}
                            onChange={setSS}
                            labelledBy="Select"
                        />
                    </Col>
                </Row> : null}
                <Row className="m-1 mt-3">
                    <Button type="submit" className="App-button m-2 mt-0" disabled={loader}>
                        {loader ? <BarLoader className="m-2" color="#FFF" width={100} /> : <>Aplicar filtros <BiSearchAlt /></>}
                    </Button>
                </Row>
            </Form>
            {consulta ?
                <div className="mb-4">
                    <Row className='row-excel-button'>
                        <h3 className="p-2" style={{ width: '50vw' }}>Resultados de reportes por Secciones</h3>
                        <Button size="sm" className="App-button" onClick={exportToExcel}>Descargar Excel</Button>
                    </Row>
                    <Table className="App-table" responsive striped hover>
                        <thead className="table-td">
                            <tr>
                                <th className="table-th" rowSpan={2}>Secciones</th>
                                {PS.filter(pnt => { if (dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) { return pnt } })
                                    .map(period => { return <th className="table-th">{period.nombre_corto}</th> })}
                            </tr>
                            <tr>
                                {PS.filter(pnt => { if (dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) { return pnt } })
                                    .map(p => {
                                        return <th>
                                            <th className="table-th p-2">Agencias</th>
                                            <th className="table-th p-2">Calificacion</th>
                                        </th>
                                    })}
                            </tr>
                        </thead>
                        <tbody>
                            {FS.map(s => {
                                return <tr>
                                    <td className="table-td">{s.nombre_seccion}</td>
                                    {PS.filter(pnt => { if (dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) { return pnt } })
                                        .map(p => {
                                            return <td className="distancia-td">
                                                {dataFiltered.filter(dt => dt.Periodo_id === p.id && dt.Seccion_id === s.id)
                                                    .map((dat) => {
                                                        return <tr className=" p-2 pt-0 pb-0">
                                                            <td className="distancia-td p-2">{dat.Nombre_sucursal}</td>
                                                            <td className={getClass(dat.Value)}>{dat.Value + '%'}</td>
                                                        </tr>
                                                    })}
                                            </td>
                                        })}
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </div> : null}
        </div>
    </>)
}