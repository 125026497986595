import { useState, useContext, useEffect } from "react";
import { Form, Button, Table, Row, Col} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import ParametrosContext from "../../../Context/ParametrosContext";
import ClientContext from "../../../Context/ClientContext";
import DataContext from "../../../Context/DataContext";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";
import UserContext from "../../../Context/UserContext";

export default function ReporteDesempeno() { 
    const API_URL = 'https://api.medicionanalytic.com/api'
    /* const API_URL = 'http://127.0.0.1:8000/api' */
    const {logData} = useContext(ClientContext)
    const {paramData} = useContext(ParametrosContext);
    const {userData} = useContext(UserContext)
    const [consulta, setConsulta] = useState(false)
    
    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()

    const [sPeriods, setSP] = useState([])
    const [sSecciones, setSS] = useState([])

    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [segmentos, setSegmentos] = useState([])
    const [categorias, setCat] = useState([])
    const [TR, setTR] = useState([])
    const [puntos, setPuntos] = useState([])
    const [sPunto, setSPto] = useState({})

    const [respuestas, setR] = useState([])
    const [dataFiltered, setDF] = useState([])
    const [PS, setPS] = useState([])

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        let Document = segmentos
        Document.forEach((d) => {delete d.tipo;
            delete d.id;
            delete d.client_id;
            delete d.created_at;
            delete d.updated_at;
            delete d.cliente_id;
            delete d.peso;
            delete d.aplica;
            delete d.estado;
            d.competencia = d.nombre_segmento;
            delete d.nombre_segmento;
            d.encuesta = encuestas.find(e => e.id === d.encuesta_id).nombre_encuesta;
            delete d.encuesta_id;
            d.brecha = (d.meta-d.promedio).toFixed(2);
            d.puntaje = (((d.promedio/d.meta)*100).toFixed(2))+"%";
        })
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }

    useEffect(()=>{
        axios.get(API_URL+'/respuesta', {params: { client_id:logData.id}}).then(res=>{
            setR(res.data)                        
        })
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(
            function(response) {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then(
            (res) => {
                res.data.forEach(p => {
                    p.label = p.nombre_sucursal;
                    p.value = p.id;
                })
                setPuntos(res.data)})
        axios.get(API_URL + '/seccion/report', { params: { client_id:logData.id } }).then(
            function (response) {
                response.data.forEach(p => {
                    p.label = p.nombre_seccion;
                    p.value = p.id;
                })
                setSecciones(response.data)})
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id} }).then(
            function(response) {
            let n = response.data.filter(encuesta => encuesta.punto_encuesta !== null);
            setEncuestas(n)})
    },[])

    function getOccurrence(array, value) {return respuestas.filter((v) => (v.seccion_id === value)).length;}

    function showData(filtered) {
        let helper = []
        filtered.forEach(v => {
            const bat = getOccurrence(filtered, v.Seccion_id)
            if (bat > 1) {
            const indet = helper.findIndex(o => o.Punto_id === v.Punto_id && o.Seccion_id === v.Seccion_id && o.Periodo_id === v.Periodo_id)
            if(indet === -1) {
                let temp = filtered.filter((q) => (q.Punto_id === v.Punto_id && q.Seccion_id === v.Seccion_id && q.Periodo_id === v.Periodo_id))
                var result = {
                    Reporte: v.Reporte,
                    Encuesta_id: v.Encuesta_id,
                    Encuesta: v.Encuesta,
                    Nombre_sucursal: puntos.find(p => p.id === v.Punto_id).nombre_sucursal,
                    Punto_id : v.Punto_id,
                    Periodo_id: v.Periodo_id,
                    Seccion : v.Seccion,
                    Seccion_id : v.Seccion_id,
                    Value : (temp.reduce(function (acc, obj) { return acc + obj.Value; }, 0)/temp.length).toFixed(2) }
                helper.push(result)
             }
           }
           else { 
            var result = {
                Reporte_id: v.Reporte_id,
                Encuesta_id: v.Encuesta_id,    
                Nombre_sucursal: puntos.find(p => p.id === v.Punto_id).nombre_sucursal,
                Punto_id : v.Punto_id,
                Periodo_id: v.Periodo_id,
                Seccion_id : v.Seccion_id,
                Value : v.Value,}
            helper.push(result)}
        })
        setDF(helper)
        setConsulta(true)
    }

    function getClass(val) {
        let x = Number(val)
        if(x < paramData.values.amarillo) {return "Fondo-R"}
        if(x < paramData.values.verde) {return "Fondo-A"}
        if(x >= paramData.values.verde) {return "Fondo-V"}
    }

    function getBrecha(val) {
        let x = Number(val)
        if(x<0.4) {return "Fondo-V"}
        if(x>0.4 && x<1) {return "Fondo-A"}
        if(x>1) {return "Fondo-R"}
    }

    const SetFilters = (data,e) => {
        let tab = Tabulada()              
        e.preventDefault();
        if(!enc) {setConsulta(false);return;}
        let temp = []
        temp =  tab.filter(el => {                
            return sSecciones.some(s => {
               return sPeriods.some(f => {
                   return el.Punto_id === sPunto.id && 
                s.id === el.Seccion_id && 
                f.id === el.Periodo_id && 
                el.Encuesta_id === enc.id
               })
            })
    })
        //there is no data for desempeño         
        setPS(sPeriods)        
        showData(temp)
    }

    function Tabulada(){
        let data; 
        let sum = []
        let res = respuestas
        if(userData.permiso === "admin" || userData.permiso === "gerente") {
            res = respuestas
        }
        else {
            res = respuestas.filter(el => {
                    return userData.puntos.split(",").indexOf(el.punto_id.toString()) > -1 || userData.secciones.split(",").indexOf(el.seccion_id.toString()) >-1 
            })
        }
        res.forEach((r)=>{
            if(r.tipo_pregunta === 'escala-tri') {
              if(r.respuesta === 'malo') {
                data = {
                Respuesta_id: r.id,
                Value :0,
                Reporte_id: r.reporte_id, 
                Punto_id: r.punto_id,
                Periodo_id: r.periodo_id,
                Encuesta_id: r.encuesta_id, 
                Seccion_id: r.seccion_id,
                Pregunta: r.pregunta,
                Tipo_pregunta: r.tipo_pregunta,
                Video_id : r.video_id
                }
                sum.push(data)
              }
              if(r.respuesta === 'regular') {
                data = {
                    Respuesta_id: r.id,
                    Value :50,
                    Reporte_id: r.reporte_id, 
                    Punto_id: r.punto_id,
                    Periodo_id: r.periodo_id,
                    Encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Pregunta: r.pregunta,
                    Tipo_pregunta: r.tipo_pregunta,
                    Video_id : r.video_id
                }
                sum.push(data)
              }
              if(r.respuesta === 'excelente') {
                data = {
                    Respuesta_id: r.id,
                    Value :100,
                    Reporte_id: r.reporte_id, 
                    Punto_id: r.punto_id,
                    Periodo_id: r.periodo_id,
                    Encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Pregunta: r.pregunta,
                    Tipo_pregunta: r.tipo_pregunta,
                    Video_id : r.video_id
                }
                sum.push(data)
              }
            }
            if(r.tipo_pregunta === "escala") {
                data = {            
                    Respuesta_id: r.id,
                    Value :(r.respuesta*100)/r.valor_max, 
                    Reporte_id: r.reporte_id, 
                    Punto_id: r.punto_id,
                    Periodo_id: r.periodo_id,
                    Encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Pregunta: r.pregunta,
                    Tipo_pregunta: r.tipo_pregunta,
                    Video_id : r.video_id
              }
                sum.push(data)
            }
            if(r.tipo_pregunta === "check") {
                if(r.respuesta === "si") {
                    data = {
                        Respuesta_id: r.id,
                        Value :100,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                    }
                    sum.push(data)
                }
                else {
                    data = {
                        Respuesta_id: r.id,
                        Value :0,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                  }
                    sum.push(data)
                }
            }
        })
        return sum;
      }
      
    const handleEncuesta = (e) => {
        e.preventDefault()
        setSS([])
        let sec = []
        if(userData.permiso === "admin" || userData.permiso === "gerente")   
        { sec = secciones}
        else {
          sec = secciones.filter(el=>{ return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
        }
        let enc = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => {return s.encuesta_id === Number(e.target.value)})
        if(enc) {
            setE(enc)
            setS(tem)
            axios.get(API_URL+'/segmento/encuesta', { params: { encuesta_id: enc.id, tipo:"pregunta" } }).then((response) =>{
                let temp = response.data
                let subcat = []                
                temp.forEach(el => {//FLAG
                    el.promedio = 0; 
                    el.meta = 5;
                    let tak = subcat.findIndex(t => String(t.subcategoria) === String(el.subcategoria));
                    if(tak === -1) 
                    {subcat.push(el)}
                })
                setSegmentos(temp)                
                setCat(subcat)
            })
        }
    }

    const handlePunto = (e) => {
        e.preventDefault()
        let temp = puntos.find(p => p.id === Number(e.target.value))
        setSPto(temp)
    }
    const setVar = (e) => {
        e.preventDefault();
        let x = categorias.find(p => p.id === Number(e.target.value))
        if(x){setTR([x])}
        else {setTR([])}
    }
    const getPromedio = (dat) => {
        let temp = dataFiltered        
        return
    }
return (<>
    <div className="content-container">
    <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">
                            <Col>
                                <MultiSelect
                                overrideStrings={{
                                    "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Periodo",
                                    "create": "Create",}
                                  }
                                 options={periodos}
                                 value={sPeriods}
                                 onChange={setSP}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <Form.Select onClick={handleEncuesta}>
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.length > 0 ? encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}): null}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todas las Secciones Seleccionadas.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",}
                                  }
                                 options={Secciones}
                                 value={sSecciones}
                                 onChange={setSS}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>                        
                                <Form.Select onClick={handlePunto}>
                                    <option value={null}>Sin Empresa Seleccionada</option>
                                    {puntos.map(pts => {return <option value={pts.id}>{pts.nombre_sucursal}</option>})}
                                </Form.Select>                           
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                  <Form.Select onChange={setVar}>
                                    <option>tipo de competencia</option>
                                    {categorias.map(c => {
                                        return <option value={c.id}>{c.subcategoria}</option>
                                    })}
                                  </Form.Select>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row className="m-1 mt-3">
                            <Button type="submit" className="App-button m-2 mt-0">Aplicar filtros <BiSearchAlt/></Button>                            
                        </Row>
    </Form>
    {consulta ? 
    <div className="mb-4">
        <Row className='row-excel-button'>
                <h3 className="p-2" style={{width:'50vw'}} onClick={() => console.log(respuestas[4])}>Resultados de Competencias generales.</h3>
                <Button size="sm" className="App-button" onClick={exportToExcel}>Descargar Excel</Button>    
        </Row>        
        <Row>        
            <Col style={{display:"inline-flex",alignItems: "baseline", justifyContent:"space-evenly"}}>
            <h6 className="p-2 table-th extended">{sPunto.nombre_sucursal}</h6>                
            {TR.map(c => {return <h6 className="p-2 table-th extended">{c.subcategoria}</h6>})}            
            <h6>{"Promedio total de competencias: 80%"}</h6>
            </Col>
        </Row>
        {TR.map(c => {return <Table className="App-Table" striped hover size="sm">            
            <thead>
                <tr>                    
                    {PS.map(period => {return <th className="table-th extended">{period.nombre_corto}</th>})}
                </tr>
                <tr>
                    {PS.map(p => {return <th>
                        <th className="table-th p-2 ">Competencia</th>
                        <th className="table-th p-2 ">Promedio de Respuesta</th>
                        <th className="table-th p-2 ">Meta</th>
                        <th className="table-th p-2 ">Brecha</th>
                        <th className="table-th p-2 ">Puntaje</th>
                    </th>})}
                </tr>
            </thead>
            <tbody>
                {PS.map(p => { return <>
                    {segmentos.filter(dt => dt.subcategoria === c.subcategoria)
                        .map((dat) => { return <tr className="result-cell p-2 pt-0 pb-0">
                        <td className="table-td p-2">{dat.nombre_segmento}</td>
                        <td className="table-td p-2">{getPromedio(dat)}</td>
                        <td className="table-td p-2">{dat.meta}</td>
                        <td className={"table-td p-2 "+getBrecha((dat.meta-dat.promedio).toFixed(2))}>{(dat.meta-dat.promedio).toFixed(2)}</td>
                        <td className={"table-td p-2 "+getClass(((dat.promedio/dat.meta)*100).toFixed(2))}>{((dat.promedio/dat.meta)*100).toFixed(2)}%</td>
                    </tr>})}                        
                </>})}                                                                  
            </tbody>
        </Table>
        })}
        </div>: null}
    </div>
    </>)
}