import {useState, useEffect, useContext} from "react"
import { Button, Form, Row, Col, Modal, Table, Toast, ToastContainer } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ClientContext from "../../../Context/ClientContext";
import axios from "axios";
import { BiEditAlt, BiTrash } from "react-icons/bi";

export default function Puntos() {
 const API_URL = 'https://api.medicionanalytic.com/api'
 /* const API_URL = 'http://127.0.0.1:8000/api' */
 const newLocation = useNavigate()
 const {logData} = useContext(ClientContext)
 const [paises, setPaises] = useState([])
 const [zonas, setZonas] = useState([])
 const [ciudades, setCiudades] = useState([])
 const [puntos, setPuntos] = useState([])
 const [selectedPais, setSP] = useState()
 const [selectedZona, setSZ] = useState()
 const [selectedCiudad, setSC] = useState()
 const [puntoView, setPointView] = useState(false)
 const [showEdit, setEditShow] = useState(false)
 const [viewElim, setElim] = useState(false)
 const [load, setLoad] = useState(false)
 const [toast, setToast] = useState({ show: false, variant: "", message: "" });
 const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}
useEffect(() => {
    axios.get(API_URL + '/pais').then((response) =>
    {setPaises(response.data)})
},[toast])

const showEliminar = (punto) => {setElim(true); reset({id:punto.id})}
const hideEliminar = () => {setElim(false)}

const showPunto = () => {
    reset({
        id: null,
        nombre_sucursal : null,
        estado: "activo",        
        group_id : null
    });
    setPointView(true)}
const hidePunto = () => {
    updateList();
    setPointView(false)}
const showEditPunto = (punto) => {
    reset({
        id: punto.id,
        nombre_sucursal : punto.nombre_sucursal,
        estado: punto.estado,        
        group_id : punto.group_id
    });
    setEditShow(true)
}
const hideEditPunto = () => {
    updateList()
    setEditShow(false)
}

const setHeaders = () => {
    let token = window.localStorage.getItem("sessionKey");
    const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
    return headers;}

const CreatePunto = (data,e) => {
    setLoad(true)
    e.preventDefault()
    axios.post(API_URL + '/sucursal', data).then(
        function (response) {
            Toaster("success", response.data.message)
            hidePunto()
            setLoad(false)
            reset({
                id: null,
                nombre_sucursal : null,
                estado: null,
            });
    })
}
const EditPunto = (data,e) => {
    setLoad(true)
    e.preventDefault()
    let headers = setHeaders()
    axios.post(API_URL + '/sucursal/update', data, {headers}).then(
        function (response) {
            Toaster("success", response.data.message)
            hideEditPunto()
            setLoad(false)
            reset({
                id: null,
                nombre_sucursal : null,
                estado: null,
            });
    })
}
const handleChange = e => {
    setSC()
    setSZ()
    e.preventDefault()
    let temp = paises.find((pais) => pais.id == e.target.value)
        axios.get(API_URL + '/zona',{ params:{pais_id:e.target.value, client_id:logData.id}}).then(
         (response) => {setZonas(response.data)}  
        )
        setSP(temp)
}
const handleZonaChange = e => {
    setSC()
    e.preventDefault()
    let temp = zonas.find((zona) => zona.id == e.target.value)
        axios.get(API_URL + '/ciudad',{ params:{zona_id:e.target.value}}).then(
            (response) => {setCiudades(response.data)}  
           )
        setSZ(temp);
}
const handleCiudadChange = e => {
    e.preventDefault()
    let temp = ciudades.find((ciudad) => ciudad.id == e.target.value)
        axios.get(API_URL + '/sucursal',{ params:{ciudad_id:e.target.value}}).then(
            (response) => {setPuntos(response.data)}  
           )
        setSC(temp);
}

function updateList() {
    axios.get(API_URL + '/sucursal',{ params:{ciudad_id:selectedCiudad.id}}).then(
        (response) => {setPuntos(response.data)}  
       )
}
const Remove = (i,e) => {
    e.preventDefault()
    let headers = setHeaders()
    axios.delete(API_URL + `/sucursal/${i.id}`, {headers}).then(res => {
        Toaster("success", res.data.message)
        hideEliminar()
        updateList()
    })
}

 return (<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={viewElim} onHide={hideEliminar}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Punto</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(Remove)}>
            <Modal.Body>
                <h4>Esta seguro ?</h4>
                <h5>esta acción no puede deshacerse</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                <Button type="submit" className="App-button">Eliminar</Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <Modal show={puntoView} onHide={hidePunto}>
        <Modal.Header closeButton>
          <Modal.Title>Nueva Sucursal</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(CreatePunto)}>
            <Form.Control type="hidden" value={logData.id} {...register("cliente_id")}/>
            <Form.Control type="hidden" defaultValue={selectedCiudad ? selectedCiudad.id : null} {...register("ciudad_id")}/>
            <Modal.Body>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Ciudad</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={selectedCiudad ? selectedCiudad.nombre_ciudad : null}/> 
                    </Form.Group>
                </Row>
                <Form.Group className="mb-2">
                    <Form.Label>Nombre de punto</Form.Label>
                    <Form.Control {...register("nombre_sucursal")} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Canal de notificaciones</Form.Label>
                    <Form.Control {...register("group_id")} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Estado</Form.Label>                
                    <Form.Select {...register("estado")}>
                        <option value="activo">Activo</option>
                        <option value="inactivo">Inactivo</option>
                    </Form.Select>
                </Form.Group>                
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hidePunto}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={load} >
                Crear
            </Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <Modal show={showEdit} onHide={hideEditPunto}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Sucursal</Modal.Title>
        </Modal.Header> 
        <Form onSubmit={handleSubmit(EditPunto)}>
            <Form.Control type="hidden" value={logData.id} {...register("cliente_id")}/>
            <Form.Control type="hidden" defaultValue={selectedCiudad ? selectedCiudad.id : null} {...register("ciudad_id")}/>
            <Modal.Body>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Ciudad</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={selectedCiudad ? selectedCiudad.nombre_ciudad : null}/> 
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Row>
                <Form.Group className="mb-2">
                    <Form.Label>nombre de punto</Form.Label>
                    <Form.Control {...register("nombre_sucursal")} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Canal de notificaciones</Form.Label>
                    <Form.Control {...register("group_id")} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Estado</Form.Label>                
                    <Form.Select {...register("estado")}>
                        <option value="activo">Activo</option>
                        <option value="inactivo">Inactivo</option>
                    </Form.Select>
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group>                
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideEditPunto}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={load}>
                Actualizar
            </Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <div className="content-container">
    <div className="content-header"><h4>País</h4></div>    
        <Form>
            <Row className="mb-2">
                <Form.Group as={Col}>
                    <Form.Select onChange={handleChange}>
                        <option>Seleccionar Pais...</option>
                        {paises.map((pais) => <option value={pais.id}>{pais.nombre_pais}</option>)}
                    </Form.Select>
                </Form.Group>
            </Row>
                {selectedPais ? <>                
                <Row className="m-1 mb-2 mt-0">
                    <Form.Group as={Col}>
                        <Form.Select onChange={handleZonaChange}>
                            <option>Seleccionar Zona</option>
                            {zonas.map((zona) => <option value={zona.id}>{zona.nombre_zona}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Button className="App-button" onClick={()=>newLocation("/app/administracion/zonas")}>Nueva zona</Button>
                    </Form.Group>
                </Row>
                </>
                : null}
                    {selectedZona ? 
                        <Row className="m-2 mb-2 mt-0">
                            <Form.Group as={Col}>
                                <Form.Select onChange={handleCiudadChange}>
                                    <option>Seleccionar Ciudad</option>
                                    {ciudades.map((ciudad) => <option value={ciudad.id}>{ciudad.nombre_ciudad}</option>)}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Button className="App-button" onClick={()=>newLocation("/app/administracion/ciudades")}>Nueva ciudad</Button>
                            </Form.Group>
                        </Row>
                    : null }
        </Form>
        {selectedCiudad ? 
            <div className="content-body-alt">
            <div className="content-header"><h4>Sucursales</h4></div>
                <Button className="m-3 App-button" onClick={showPunto}> Nuevo punto </Button>
                <Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>                        
                            <th className="table-th">nombre de punto</th>
                            <th className="table-th">estado</th>                        
                            <th></th>                        
                        </tr>
                    </thead>
                    <tbody>
                            {puntos.map((punto) => 
                               <tr>                                
                                    <td>{punto.nombre_sucursal}</td>
                                    <td>{punto.estado}</td>
                                    <td className="clickable-end">
                                        <td className="clickable"><BiEditAlt onClick={() => showEditPunto(punto)} /></td>
                                        <td className="clickable"><BiTrash onClick={() => showEliminar(punto)} /></td>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </Table>
        </div>:null}
        <div className="flex-between">
        <Button className="App-button" onClick={() => {newLocation("../ciudades")}}>Atrás</Button>
        <Button className="App-button" onClick={() => {newLocation("../segmentacion-puntos")}}>Siguiente</Button>
        </div>
    </div>
    </>)
}